"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const networkStatistics_1 = require("../actions/networkStatistics");
const initialState = {
    bitrate: null,
};
/**
 * Updates the network statistics part of the Redux State.
 * @param {INetworkStatisticsState} state Current NetworkStatistics state.
 * @param {NetworkStatisticsAction} action Redux Action requesting a state update.
 * @return {INetworkStatisticsState} Updated state as a result of the action.
 */
exports.NetworkStatisticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case networkStatistics_1.NetworkStatisticsActionTypes.UPDATE_BITRATE:
            return Object.assign({}, state, { bitrate: action.payload.bitrate });
        default:
            return state;
    }
};
