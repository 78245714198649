"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const events_1 = require("events");
const AmazonVideoAdsError_1 = require("../errors/AmazonVideoAdsError");
const VPAIDEvents_1 = require("../constants/VPAIDEvents");
const Analytics_1 = require("../controllers/Analytics");
exports.getVPAIDVersion = (versionString) => {
    const parts = versionString.split('.').map(parseFloat);
    return {
        string: versionString,
        major: parts[0],
        minor: parts[1],
        patch: parts[2],
    };
};
exports.parseIcon = (data) => {
    const icon = data.staticResource
        ? document.createElement('img')
        : document.createElement('iframe');
    let src;
    if ((data.staticResource || '').length > 0) {
        src = data.staticResource;
    }
    else if ((data.iframeResource || '').length > 0) {
        src = data.iframeResource;
    }
    else {
        src = data.htmlResource;
    }
    icon.className = 'aps-icons';
    icon.style.width = `${data.width}px`;
    icon.style.height = `${data.height}px`;
    const xPosition = parseInt(data.xPosition, 10);
    const yPosition = parseInt(data.yPosition, 10);
    icon.style.left = xPosition >= 0 ? `${xPosition}px` : '0px';
    icon.style.top = yPosition >= 0 ? `${yPosition}px` : '0px';
    if ((data.staticResource || '').length > 0) {
        icon.src = src;
    }
    else {
        icon.sandbox = 'allow-scripts allow-same-origin';
        icon.style.border = 'none';
        icon.style.overflow = 'hidden';
        icon.setAttribute('scrolling', 'no');
        icon.setAttribute('allow', 'autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; encrypted-media');
        icon.setAttribute('sandbox', 'allow-scripts allow-presentation allow-same-origin');
        if (data.htmlResource) {
            icon.srcdoc = src;
        }
        else {
            icon.src = src;
        }
    }
    if (data.iconViewTrackingURLTemplate) {
        icon.onload = () => {
            const image = window.document.createElement('img');
            image.onerror = () => Analytics_1.analytics.recordEvent({
                [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: AmazonVideoAdsError_1.AmazonErrorCode.VPAID_GENERAL,
                [Analytics_1.AnalyticsEventAttribute.ERROR_MESSAGE]: 'iconViewTrackingURLTemplate onload error',
            });
            image.onload = () => Analytics_1.analytics.recordEvent({
                [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: Analytics_1.AnalyticsEventType.VPAID_IVTUT_SUCCESS,
            });
            image.src = data.iconViewTrackingURLTemplate;
        };
        icon.onerror = (e) => Analytics_1.analytics.recordEvent({
            [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: AmazonVideoAdsError_1.AmazonErrorCode.VPAID_GENERAL,
            [Analytics_1.AnalyticsEventAttribute.ERROR_MESSAGE]: 'icon onload error',
        });
    }
    if (data.iconClickThroughURLTemplate) {
        icon.addEventListener('touchend', (e) => exports.onIconClick(data, e));
        icon.addEventListener('click', (e) => exports.onIconClick(data, e));
    }
    return icon;
};
exports.onIconClick = (icon, event) => {
    event.stopPropagation();
    if (event.type === 'touchend') {
        event.preventDefault();
    }
    window.open(icon.iconClickThroughURLTemplate, '_blank');
    Analytics_1.analytics.recordEvent({
        [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: Analytics_1.AnalyticsEventType.VPAID_ICTUT_SUCCESS,
    });
    if (icon.iconClickTrackingURLTemplates.length > 0) {
        icon.iconClickTrackingURLTemplates.forEach((url) => {
            // note: document.createElement is used over new Image for easier test mocking
            const image = window.document.createElement('img');
            image.onerror = () => Analytics_1.analytics.recordEvent({
                [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: AmazonVideoAdsError_1.AmazonErrorCode.VPAID_GENERAL,
                [Analytics_1.AnalyticsEventAttribute.ERROR_MESSAGE]: 'icon onload error',
            });
            image.onload = () => Analytics_1.analytics.recordEvent({
                [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: Analytics_1.AnalyticsEventType.VPAID_ICTUTS_SUCCESS,
            });
            image.src = url;
        });
    }
};
class VSDKVPAID extends events_1.EventEmitter {
    constructor(options) {
        super();
        this.adLinear = {
            get: this.proxy('getAdLinear'),
        };
        this.adWidth = {
            get: this.proxy('getAdWidth'),
        };
        this.adHeight = {
            get: this.proxy('getAdHeight'),
        };
        this.adExpanded = {
            get: this.proxy('getAdExpanded'),
        };
        this.adSkippableState = {
            get: this.proxy('getAdSkippableState'),
        };
        this.adRemainingTime = {
            get: this.proxy('getAdRemainingTime'),
        };
        this.adDuration = {
            get: this.proxy('getAdDuration'),
        };
        this.adCompanions = {
            get: this.proxy('getAdCompanions'),
        };
        this.adIcons = {
            get: this.proxy('getAdIcons'),
        };
        this.resizeAd = this.proxy('resizeAd', VPAIDEvents_1.VPAIDEvents.AdSizeChange);
        this.stopAd = this.proxy('stopAd', VPAIDEvents_1.VPAIDEvents.AdStopped);
        this.expandAd = this.proxy('expandAd', VPAIDEvents_1.VPAIDEvents.AdExpandedChange);
        this.collapseAd = this.proxy('collapseAd', VPAIDEvents_1.VPAIDEvents.AdExpandedChange);
        this.skipAd = this.proxy('skipAd', VPAIDEvents_1.VPAIDEvents.AdSkipped);
        this.hasLoaded = false;
        this.autoplayOverride = false;
        this.hasIconsRendered = false;
        this.adVolume = {
            get: this.proxy('getAdVolume'),
            set: this.proxy('setAdVolume'),
        };
        this.retryAttempts = 0;
        this.options = options;
        try {
            this.load();
        }
        catch (e) {
            this.onAdError(e);
        }
    }
    // External method that the video player SDK uses to trigger VPAID to play
    startAd(volume) {
        // If the ad has been triggered to start by the player by calling startAd elsewhere,
        // this means autoplay should occur
        this.autoplayOverride = true;
        if (this.isPaused) {
            this.setVolume(volume);
            this.resumeAd();
            return;
        }
        this.isPaused = false;
        this.proxy('startAd', VPAIDEvents_1.VPAIDEvents.AdStarted)();
        this.setVolume(volume);
    }
    setVolume(volume) {

        if (typeof volume !== 'number' || volume < 0) {
            return;
        }
        // Set volume direct for ads that do not respect the adVolume event bridge.
        this.video.volume = volume > 1 ? 1 : volume;
        this.adVolume.set(volume);
    }
    getVolume() {
        const volume = this.adVolume.get();
        if (typeof volume === 'number' && volume >= 0) {
            return volume;
        }
        if (typeof this.video.volume === 'number' && this.video.volume >= 0) {
            return this.video.volume;
        }
        return -1;
    }
    resumeAd() {
        if (!this.isPaused) {
            return;
        }
        return this.proxy('resumeAd', VPAIDEvents_1.VPAIDEvents.AdPlaying)();
    }
    pauseAd() {

        if (this.isPaused) {
            return;
        }
        this.proxy('pauseAd', VPAIDEvents_1.VPAIDEvents.AdPaused)();
    }
    load() {
        this.iframeNode = document.createElement('iframe');
        this.iframeNode.src = 'about:blank';
        this.iframeNode.style.width = '100%';
        this.iframeNode.style.height = '100%';
        this.iframeNode.style.display = 'block';
        this.iframeNode.style.opacity = '0';
        this.iframeNode.style.border = 'none';
        this.slot = document.createElement('div');
        this.slot.style.position = 'absolute';
        this.slot.style.top = '0';
        this.slot.style.left = '0';
        this.slot.style.margin = '0';
        this.slot.style.padding = '0';
        this.slot.style.width = '100%';
        this.slot.style.height = '100%';
        this.slot.style.outline = 'none';
        this.slot.style.display = 'block';
        this.video = document.createElement('video');
        this.video.setAttribute('webkit-playsinline', 'true');
        this.video.style.display = 'block';
        this.video.style.width = '100%';
        this.video.style.height = '100%';
        this.video.style.objectFit = 'contain';
        const script = document.createElement('script');
        script.src = this.options.mediaFileUrl;
        script.onload = () => this.onVPAIDScriptLoad();
        script.onerror = () => Analytics_1.analytics.recordEvent({
            [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: AmazonVideoAdsError_1.AmazonErrorCode.VPAID_GENERAL,
            [Analytics_1.AnalyticsEventAttribute.ERROR_MESSAGE]: 'script failed loading',
        });
        this.iframeNode.onload = () => {
            this.iframeNode.contentWindow.document.body.appendChild(this.video);
            this.iframeNode.contentWindow.document.body.appendChild(this.slot);
            this.iframeNode.contentWindow.document.head.appendChild(script);
            this.iframeNode.contentWindow.document.body.style.margin = '0px';
        };
    }
    getIFrameNode() {
        return this.iframeNode;
    }
    onIFrameResize() {
        if (!this.iframeNode) {
            return;
        }
        const { width, height } = this.iframeNode.getBoundingClientRect();
        if (width <= 0 || height <= 0) {
            return;
        }
        if (this.hasLoaded) {
            this.resizeAd(width, height, 'normal');
        }
    }
    onVPAIDScriptLoad() {
        this.api = this.iframeNode.contentWindow.getVPAIDAd();
        const position = this.iframeNode.getBoundingClientRect();
        const version = exports.getVPAIDVersion(this.api.handshakeVersion('2.0'));
        if (version.major > 2) {
            return this.recordFailure(new Error('Version is not supported'));
        }
        this.iframeNode.contentWindow.addEventListener('resize', this.onIFrameResize, false);
        Object.keys(VPAIDEvents_1.VPAIDEvents).forEach((event) => {
            return this.api.subscribe((...args) => {

                return this.emit.apply(this, [event].concat(args));
            }, event);
        });
        this.once(VPAIDEvents_1.VPAIDEvents.AdLoaded, this.onAdLoaded);
        this.on(VPAIDEvents_1.VPAIDEvents.AdError, this.onAdError);
        this.on(VPAIDEvents_1.VPAIDEvents.AdDurationChange, this.handleAdDurationChange);
        this.on(VPAIDEvents_1.VPAIDEvents.AdPaused, this.handleAdPaused);
        this.on(VPAIDEvents_1.VPAIDEvents.AdStarted, this.handleAdStarted);
        this.on(VPAIDEvents_1.VPAIDEvents.AdVideoStart, this.handleAdVideoStart);
        this.on(VPAIDEvents_1.VPAIDEvents.AdPlaying, this.handleAdPlaying);
        this.on(VPAIDEvents_1.VPAIDEvents.AdClickThru, this.handleAdClickThru);
        this.on(VPAIDEvents_1.VPAIDEvents.AdVideoFirstQuartile, this.handleAdVideoUpdate);
        this.on(VPAIDEvents_1.VPAIDEvents.AdVideoMidpoint, this.handleAdVideoUpdate);
        this.on(VPAIDEvents_1.VPAIDEvents.AdVideoThirdQuartile, this.handleAdVideoUpdate);
        this.on(VPAIDEvents_1.VPAIDEvents.AdVideoComplete, this.handleAdVideoComplete);
        this.on(VPAIDEvents_1.VPAIDEvents.AdVolumeChange, this.onAdVolumeChange);

        this.api.initAd(position.width, position.height, 'normal', null, // bitrate
        { AdParameters: this.options.adParams }, { slot: this.slot, videoSlot: this.video, videoSlotCanAutoPlay: true });
    }
    onAdLoaded() {
        this.iframeNode.style.opacity = '1';
        this.hasLoaded = true;
        this.options.vsdk.onLoadedMetadata({
            target: this.video,
        });

        this.recordSuccess();
        if (this.options.vsdk.isAutoplay || this.autoplayOverride) {
            this.proxy('startAd', VPAIDEvents_1.VPAIDEvents.AdStarted)();
        }
        else {
            this.options.vsdk.onPause();
        }
    }
    onAdError(reason) {

        const message = typeof reason === 'string'
            ? reason
            : (reason || {}).message || '';
        // Checks if this is an playback initiated before user interaction error
        if (this.retryAttempts++ <= 2 &&
            (message.indexOf('without user interaction') > -1 ||
                message.indexOf('play()') > -1 ||
                message.indexOf('xX8pDD') > -1 ||
                message.indexOf('AdError 1205') > -1 ||
                message.indexOf('Autoplay attempted') > -1)) {
            if (!this.isPaused) {
                this.options.vsdk.onPause();
            }
            setTimeout(() => this.startAd(), 1000);
            return;
        }
        if (this.options.vsdk.onError) {
            this.options.vsdk.onError(reason);
        }
        this.iframeNode.style.display = 'none';
        this.recordFailure(reason);
    }
    handleAdDurationChange() {
        this.options.vsdk.onLoadedMetadata({
            target: this.video,
        });
    }
    handleAdPaused() {

        this.isPaused = true;
        if (this.options.vsdk.onPause) {
            this.options.vsdk.onPause();
        }
    }
    handleAdStarted() {
        this.handleIcons();
        if (this.options.vsdk.onPlay) {
            this.options.vsdk.onPlay();
        }
    }
    handleAdVideoStart() {
        this.isPaused = false;

        // triggers impression
        // the video is known to be playing and is the most reliable source of truth
        this.options.vsdk.onTimeUpdate({
            target: {
                currentTime: 0.1,
            },
        });
    }
    handleAdPlaying() {
        this.isPaused = false;
        if (this.options.vsdk.onPlay) {

            this.options.vsdk.onPlay();
        }
    }
    getAdProgress() {
        const [duration, remainingTime] = [
            this.adDuration.get(),
            this.adRemainingTime.get(),
        ];
        // API spec is off, return > 0 to trigger impression as needed, but do not assume
        // progress is working past that
        if (typeof duration !== 'number' ||
            typeof remainingTime !== 'number' ||
            remainingTime < 0 ||
            duration < 0) {
            return 0.1;
        }
        return duration - remainingTime;
    }
    handleAdVideoUpdate() {
        const apiTime = this.getAdProgress();
        const currentTime = this.video.currentTime > apiTime ? this.video.currentTime : apiTime;
        this.options.vsdk.onTimeUpdate({
            target: {
                currentTime,
            },
        });

    }
    handleAdVideoComplete() {
        const apiTime = this.getAdProgress();
        const currentTime = this.video.currentTime > apiTime ? this.video.currentTime : apiTime;
        this.options.vsdk.onTimeUpdate({
            target: {
                currentTime,
            },
        });
        this.options.vsdk.onEnded();
        this.recordComplete();

    }
    handleAdClickThru(url, id, playerHandles) {

        if (!playerHandles) {

            return; // vpaid ad handles opening the url
        }
        if (this.options.vsdk.onClick) {
            this.options.vsdk.onClick((url || '').length > 0 ? { url } : undefined);
        }
    }
    onAdVolumeChange() {
        const volume = this.adVolume.get(); // returns -1 when VPAID ad does not support adVolume
        if (volume >= 0 && this.options.vsdk.onVolumeChange) {
            this.options.vsdk.onVolumeChange();
        }
    }
    proxy(method, event) {
        return (...args) => {
            const api = this.api;
            const getError = (reason) => {

                return new Error('Ad has not been loaded. ' + reason);
            };
            const call = () => api[method].apply(api, args);
            if (!event) {
                if (!api) {
                    throw getError(JSON.stringify({ event: !!event, api: !!api }));
                }
                return call();
            }
            return new Promise((resolve, reject) => {
                if (!api) {
                    return reject(getError(JSON.stringify({ api: !!api })));
                }
                this.once(event, function done() {
                    resolve(this);
                });
                return call();
            });
        };
    }
    handleIcons() {
        if (this.hasIconsRendered) {
            return;
        }

        // Check if vpaid handles icons, our player does nothing in this case.
        if (typeof this.adIcons.get === 'function' && this.adIcons.get()) {
            return;
        }
        if ((this.options.icons || []).length === 0) {
            return;
        }
        this.hasIconsRendered = true;
        this.options.icons.forEach((icon) => this.slot.appendChild(exports.parseIcon(icon)));
    }
    recordFailure(error) {
        Analytics_1.analytics.recordEvent({
            [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: Analytics_1.AnalyticsEventType.VPAID_AD_SESSION_FAILURE,
            [Analytics_1.AnalyticsEventAttribute.ERROR_MESSAGE]: JSON.stringify({ error }),
            [Analytics_1.AnalyticsEventAttribute.ERROR_CODE]: AmazonVideoAdsError_1.AmazonErrorCode.VPAID_GENERAL,
        });

    }
    recordSuccess() {
        Analytics_1.analytics.recordEvent({
            [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: Analytics_1.AnalyticsEventType.VPAID_AD_SESSION_SUCCESS,
        });
    }
    recordComplete() {
        Analytics_1.analytics.recordEvent({
            [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: Analytics_1.AnalyticsEventType.VPAID_AD_SESSION_COMPLETE,
        });
    }
}
exports.VSDKVPAID = VSDKVPAID;
