"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Creative_1 = require("./Creative");
class CreativeLinear extends Creative_1.Creative {
    constructor(creativeAttributes = {}) {
        super(creativeAttributes);
        this.type = 'linear';
        this.duration = 0;
        this.skipDelay = null;
        this.mediaFiles = [];
        this.videoClickThroughURLTemplate = null;
        this.videoClickTrackingURLTemplates = [];
        this.videoCustomClickURLTemplates = [];
        this.adParameters = null;
        this.icons = [];
        this.trackingEvents = {};
    }
}
exports.CreativeLinear = CreativeLinear;
