"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const VPAID_1 = require("../utils/VPAID");
class VPAIDPlayer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.vsdkVPAID = new VPAID_1.VSDKVPAID({
            mediaFileUrl: this.props.mediaFileUrl,
            adParams: this.props.adParams,
            trackingEvents: this.props.trackingEvents,
            icons: this.props.icons,
            // Callback bridge between player <==> vpaid
            vsdk: {
                onLoadedMetadata: this.props.onLoadedMetadata,
                onEnded: this.props.onEnded,
                onError: this.props.onError,
                onPlay: this.props.onPlay,
                onPause: this.props.onPause,
                onClick: this.props.onClick,
                onTimeUpdate: this.props.onTimeUpdate,
                onVolumeChange: this.props.onVolumeChange,
                isAutoplay: this.props.isAutoplay,
            },
        });
    }
    componentDidMount() {
        this.containerRef.current.appendChild(this.vsdkVPAID.getIFrameNode());
    }
    /**
     * Renders the jsx template for the Component.
     * @return {jsx} jsx template.
     */
    render() {

        return React.createElement("div", { ref: this.containerRef, id: 'vpaid-container' });
    }
}
exports.VPAIDPlayer = VPAIDPlayer;
