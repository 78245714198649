"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class VASTResponse {
    constructor(ads) {
        this.ads = ads;
        this.sortAdsBySequence();
    }
    sortAdsBySequence() {
        this.ads = this.ads.sort((a, b) => {
            // Prioritize displaying the AdPod (containing a sequence attribute) over standalone ads (with no sequence).
            if (!a.sequence) {
                if (!b.sequence) {
                    // No AdPod, don't care about order
                    return 0;
                }
                // B is an Ad in an AdPod. A is not.
                return 1;
            }
            if (!b.sequence) {
                // A is an Ad in an AdPod. B is not
                return -1;
            }
            // Both A and B are Ads in an AdPod with a sequence
            return a.sequence - b.sequence;
        });
    }
}
exports.VASTResponse = VASTResponse;
