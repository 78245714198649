"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class AdVerification {
    constructor(adVerificationAttributes = {}) {
        this.vendor = adVerificationAttributes.vendor || null;
        this.jsResource = [];
        this.parameters = adVerificationAttributes.parameters || null;
        this.trackingEvents = adVerificationAttributes.trackingEvents || {};
    }
}
exports.AdVerification = AdVerification;
