"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const CreativeNonLinear_1 = require("../../model/CreativeNonLinear");
const NonLinearAd_1 = require("../../model/NonLinearAd");
const CreativeParser_1 = require("./CreativeParser");
/**
 * Provides methods to parse a VAST NonLinear Element.
 * @class CreativeNonLinearParser
 */
class CreativeNonLinearParser extends CreativeParser_1.CreativeParser {
    /**
     * Creates an instance of CreativeNonLinearParser.
     */
    constructor() {
        super();
    }
    /**
     * Parses a NonLinear element.
     * @param {Element} creativeElement the VAST NonLinear element to parse.
     * @param {CreativeAttributes} creativeAttributes the attributes of the NonLinear (optional).
     * @return {CreativeNonLinear} the parsed CreativeNonLinear
     */
    parse(creativeElement, creativeAttributes) {
        const creative = new CreativeNonLinear_1.CreativeNonLinear(creativeAttributes);
        this.parserUtils
            .childrenByName(creativeElement, 'TrackingEvents')
            .forEach((trackingEventsElement) => {
            this.parserUtils
                .childrenByName(trackingEventsElement, 'Tracking')
                .forEach((trackingElement) => {
                const eventName = trackingElement.getAttribute('event');
                const trackingURLTemplate = this.parserUtils.parseNodeText(trackingElement);
                if (eventName && trackingURLTemplate) {
                    if (!creative.trackingEvents[eventName]) {
                        creative.trackingEvents[eventName] = [];
                    }
                    creative.trackingEvents[eventName].push(trackingURLTemplate);
                }
            });
        });
        this.parserUtils
            .childrenByName(creativeElement, 'NonLinear')
            .forEach((nonlinearResource) => {
            const nonlinearAd = new NonLinearAd_1.NonLinearAd();
            nonlinearAd.id = nonlinearResource.getAttribute('id') || null;
            nonlinearAd.width = parseInt(nonlinearResource.getAttribute('width'), 10);
            nonlinearAd.height = parseInt(nonlinearResource.getAttribute('height'), 10);
            nonlinearAd.expandedWidth = parseInt(nonlinearResource.getAttribute('expandedWidth'), 10);
            nonlinearAd.expandedHeight = parseInt(nonlinearResource.getAttribute('expandedHeight'), 10);
            nonlinearAd.scalable = this.parserUtils.parseBoolean(nonlinearResource.getAttribute('scalable'));
            nonlinearAd.maintainAspectRatio = this.parserUtils.parseBoolean(nonlinearResource.getAttribute('maintainAspectRatio'));
            nonlinearAd.minSuggestedDuration = this.parserUtils.parseDuration(nonlinearResource.getAttribute('minSuggestedDuration'));
            nonlinearAd.apiFramework =
                nonlinearResource.getAttribute('apiFramework');
            this.parserUtils
                .childrenByName(nonlinearResource, 'HTMLResource')
                .forEach((htmlElement) => {
                nonlinearAd.type =
                    htmlElement.getAttribute('creativeType') || 'text/html';
                nonlinearAd.htmlResource =
                    this.parserUtils.parseNodeText(htmlElement);
            });
            this.parserUtils
                .childrenByName(nonlinearResource, 'IFrameResource')
                .forEach((iframeElement) => {
                nonlinearAd.type = iframeElement.getAttribute('creativeType');
                nonlinearAd.iframeResource =
                    this.parserUtils.parseNodeText(iframeElement);
            });
            this.parserUtils
                .childrenByName(nonlinearResource, 'StaticResource')
                .forEach((staticElement) => {
                nonlinearAd.type = staticElement.getAttribute('creativeType');
                nonlinearAd.staticResource =
                    this.parserUtils.parseNodeText(staticElement);
            });
            const adParamsElement = this.parserUtils.childByName(nonlinearResource, 'AdParameters');
            if (adParamsElement) {
                nonlinearAd.adParameters =
                    this.parserUtils.parseNodeText(adParamsElement);
            }
            nonlinearAd.nonlinearClickThroughURLTemplate =
                this.parserUtils.parseNodeText(this.parserUtils.childByName(nonlinearResource, 'NonLinearClickThrough'));
            this.parserUtils
                .childrenByName(nonlinearResource, 'NonLinearClickTracking')
                .forEach((clickTrackingElement) => {
                nonlinearAd.nonlinearClickTrackingURLTemplates.push(this.parserUtils.parseNodeText(clickTrackingElement));
            });
            creative.variations.push(nonlinearAd);
        });
        return creative;
    }
}
exports.CreativeNonLinearParser = CreativeNonLinearParser;
