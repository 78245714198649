"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const VPAIDPlayer_1 = require("./VPAIDPlayer");
const EndCard_1 = require("./EndCards/EndCard");
/**
 * Entry Component for the player.
 * @class
 */
class Player extends React.Component {
    /**
     * Constructor.
     * @constructor
     * @param {IPlayerProps} props React props passed to the component.
     */
    constructor(props) {
        super(props);
    }
    /**
     * Renders the jsx template for the Component.
     * @return {jsx} jsx template.
     */
    render() {

        const player = {
            cursor: 'pointer',
            height: '100%',
            width: '100%',
            pointerEvents: 'auto',
        };
        return (React.createElement(React.Fragment, null,
            !this.props.isVPAID && this.props.endCardAd && (React.createElement(EndCard_1.EndCard, { ad: this.props.endCardAd, isHidden: !this.props.isLastFrame, isShownEarly: this.props.endCardsShown, isLastFrame: this.props.isLastFrame, onPlayerPlayPause: this.props.onPlayerPlayPause, errorURLTemplates: this.props.errorURLTemplates, isPlayerPaused: this.props.isPlayerPaused, onEndCardClose: this.props.handleEndCardClose, onEndCardRendered: this.props.handleEndCardRendered })),
            this.props.isVPAID && (React.createElement(VPAIDPlayer_1.VPAIDPlayer, { ref: this.props.vpaidPlayerRef, mediaFileUrl: this.props.mediaFileUrl, adParams: this.props.vpaidAdParams, muted: this.props.muted, controls: this.props.controls, onLoadedMetadata: this.props.handleAdLoadedMetadata, onVolumeChange: this.props.handleAdVolumeChange, onWaiting: this.props.handleAdWaiting, onError: this.props.handleAdError, onPlay: this.props.handleAdPlay, onPause: this.props.handleAdPause, onEnded: this.props.handleAdEnded, onClick: this.props.handleAdClick, onTimeUpdate: this.props.handleAdProgress, onSeeking: this.props.handleAdSeeking, trackingEvents: this.props.vpaidTrackingEvents, icons: this.props.vpaidIcons, isAutoplay: this.props.isAutoplay })),
            !this.props.isVPAID && (React.createElement("video", { style: player, muted: this.props.muted, controls: this.props.controls, preload: this.props.preload, onLoadedMetadata: this.props.handleAdLoadedMetadata, onVolumeChange: this.props.handleAdVolumeChange, onWaiting: this.props.handleAdWaiting, onError: this.props.handleAdError, onPlay: this.props.handleAdPlay, onPause: this.props.handleAdPause, onEnded: this.props.handleAdEnded, onClick: this.props.handleAdClick, onTimeUpdate: this.props.handleAdProgress, onSeeking: this.props.handleAdSeeking, ref: this.props.videoRef, src: this.props.mediaFileUrl, playsInline: true }))));
    }
}
exports.Player = Player;
