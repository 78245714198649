"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const Analytics_1 = require("../../controllers/Analytics");
const CompanionController_1 = require("../../controllers/CompanionController");
class EndCardAd extends React.Component {
    constructor(props) {
        super(props);
        this.LOG_CONTEXT = '<EndCardAd />: ';
        this.containerRef = React.createRef();
    }
    componentDidMount() {
        this.renderEndCard();
    }
    render() {
        return (React.createElement("div", { id: 'vsdk-endcard', "data-testid": 'vsdk-endcard', ref: this.containerRef, style: {
                display: 'block',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                background: '#000',
                zIndex: 1,
            } }));
    }
    renderEndCard() {
        const isSuccessful = CompanionController_1.renderCompanionInDIV(this.parseEndCardAd(), this.containerRef.current, [], {
            isEndCard: true,
            reportError: console.warn,
            onAsyncError: (error) => this.props.onErrorCleanup({ error }),
        });
        if (!isSuccessful) {
            Analytics_1.analytics.recordEvent({
                [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: Analytics_1.AnalyticsEventType.END_CARD_RENDER_ERROR,
            });
            this.props.onErrorCleanup({
                error: new Error('Failed to render end card'),
            });
            return;
        }
        this.attemptToApplyIFrameStyle();
        this.attemptToApplyIFrameClickThrough();
    }
    parseEndCardAd() {
        const adToRender = Object.assign({}, this.props.ad);
        // Specify our end card render priority as
        // 1 - iframe
        // 2 - html
        // 3 - static
        if ((adToRender.iframeResource || '').length > 0) {
            delete adToRender.htmlResource;
            delete adToRender.staticResource;
        }
        else if ((adToRender.htmlResource || '').length > 0) {
            delete adToRender.iframeResource;
            delete adToRender.staticResource;
        }
        return adToRender;
    }
    attemptToApplyIFrameStyle() {
        try {
            const refChild = this.containerRef.current.firstChild;
            if (this.parseEndCardAd().iframeResource) {
                refChild.addEventListener('load', () => {
                    refChild.contentDocument.body.style.padding =
                        '0';
                    refChild.contentDocument.body.style.margin =
                        '0';
                });

            }
        }
        catch (e) {

        }
    }
    attemptToApplyIFrameClickThrough() {
        try {
            const iframe = (this.containerRef.current.getElementsByTagName('iframe') || [])[0];
            if (!iframe) {

                return;
            }
            exports.addIFrameClickListener({
                iframe,
                isStaticResource: (this.parseEndCardAd().staticResource || '').length > 0,
                companionClickThroughURLTemplate: this.props.ad.companionClickThroughURLTemplate,
                onClick: this.props.onClick.bind(this),
            });
        }
        catch (e) {

        }
    }
}
exports.EndCardAd = EndCardAd;
exports.addIFrameClickListener = ({ iframe, isStaticResource, companionClickThroughURLTemplate, onClick, }) => {
    const attachIframeListeners = () => {
        if (!iframe.contentDocument) {
            return;
        }
        const handleOnClick = () => {
            if (isStaticResource &&
                window.mraid &&
                window.mraid.open &&
                (companionClickThroughURLTemplate || '').length > 0) {
                window.mraid.open(companionClickThroughURLTemplate);
            }
            onClick();
        };
        iframe.contentDocument.addEventListener('click', handleOnClick);
        iframe.contentDocument.addEventListener('touchstart', handleOnClick);
    };
    // Attach the listener on load in case the iframe hasn't loaded yet
    iframe.addEventListener('load', (e) => {
        attachIframeListeners();
    });
    // Check if iframe is already loaded and attach listeners immediately if so
    if (iframe.contentDocument &&
        iframe.contentDocument.readyState === 'complete') {
        attachIframeListeners();
    }
};
