"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const PlayerController_1 = require("./PlayerController");
const IframeBridge_1 = require("./IframeBridge");
const index_1 = require("../index");
const player_1 = require("../actions/player");
/*
 * Listen to these cross-iframe events emitted by the ad video player.
 */
var AdVideoPlayerEvents;
(function (AdVideoPlayerEvents) {
    AdVideoPlayerEvents["CLICKED"] = "AD_VIDEO_PLAYER_CLICKED";
    AdVideoPlayerEvents["COMPLETED"] = "AD_VIDEO_PLAYER_COMPLETED";
    AdVideoPlayerEvents["ERRORED"] = "AD_VIDEO_PLAYER_ERRORED";
    AdVideoPlayerEvents["FIRST_QUARTILE"] = "AD_VIDEO_PLAYER_FIRST_QUARTILE";
    AdVideoPlayerEvents["IMPRESSION"] = "AD_VIDEO_PLAYER_IMPRESSION";
    AdVideoPlayerEvents["LOADED"] = "AD_VIDEO_PLAYER_LOADED";
    AdVideoPlayerEvents["MIDPOINT"] = "AD_VIDEO_PLAYER_MIDPOINT";
    AdVideoPlayerEvents["MUTED"] = "AD_VIDEO_PLAYER_MUTED";
    AdVideoPlayerEvents["PAUSED"] = "AD_VIDEO_PLAYER_PAUSED";
    AdVideoPlayerEvents["PROGRESS"] = "AD_VIDEO_PLAYER_PROGRESS";
    AdVideoPlayerEvents["REQUEST_PAUSE"] = "REQUEST_CONTENT_VIDEO_PLAYER_PAUSE";
    AdVideoPlayerEvents["REQUEST_RESUME"] = "REQUEST_CONTENT_VIDEO_PLAYER_RESUME";
    AdVideoPlayerEvents["RESUMED"] = "AD_VIDEO_PLAYER_RESUMED";
    AdVideoPlayerEvents["STARTED"] = "AD_VIDEO_PLAYER_STARTED";
    AdVideoPlayerEvents["THIRD_QUARTILE"] = "AD_VIDEO_PLAYER_THIRD_QUARTILE";
    AdVideoPlayerEvents["UNMUTED"] = "AD_VIDEO_PLAYER_UNMUTED";
    AdVideoPlayerEvents["VOLUME_CHANGE"] = "AD_VIDEO_PLAYER_VOLUME_CHANGE";
})(AdVideoPlayerEvents = exports.AdVideoPlayerEvents || (exports.AdVideoPlayerEvents = {}));
class AdsManager {
    /**
     * Publisher's interface with the SDK.
     * @constructor
     * @param {HTMLIFrameElement} iframeNode The iframe that contains the SDK.
     * @param {IAdsManagerOptions} options Options used to fetch and play ads.
     */
    constructor(iframeNode, options, resetIframe) {
        /**
         * Handle the AdVideoPlayerEvents.REQUEST_RESUME emitted by the ad player.
         * This event signals the end of the final ad in the ad pod so we should destroy the AdsManager
         */
        this.onRequestResume = () => {
            clearInterval(this.detectRemoval);
            this.messageBridge.destroy();
        };
        this.iframeNode = iframeNode;
        this.videoPlayStallTimeout = options.videoPlayStallTimeout;
        this.startVideoPlayTimeout = options.startVideoPlayTimeout;
        this.adBreakBufferTimeout = options.adBreakBufferTimeout;
        this.bufferingSpinnerColor = options.bufferingSpinnerColor;
        this.messageBridge = new IframeBridge_1.IframeBridge(this.iframeNode.contentWindow, window);
        this.resetIframe = resetIframe;
        this.detectRemoval = window.setInterval(() => {
            if (!this.iframeNode.contentDocument) {
                clearInterval(this.detectRemoval);
                this.cancelAdBreak();
                if (this.errorCallback) {
                    try {
                        this.errorCallback({
                            errorCode: 'User agent intervention',
                        });
                    }
                    catch (error) {

                    }
                }
                this.resetIframe();
            }
        }, 50);
        this.addListeners();
        this.store = index_1.store;
    }
    /**
     * Used by the content player to tell the SDK to start playing ads.
     * @param {number} bitrate the bitrate of the content player that the SDK should use.
     * If no bitrate is provided, the highest bitrate for each media file will be used.
     * @param {boolean} shouldVideoRemainAfterPlay whether ad unit should remain at end card.
     * If no shouldVideoRemainAfterPlay is provided, ad unit will be destroyed when it is finished playing
     * @param {boolean} disableClickThrough whether video player should handle click through operation
     * If no disableClickThrough is provided, it will support click through ability
     */
    startAds(bitrate, shouldVideoRemainAfterPlay, disableClickThrough) {
        const values = {
            bitrate,
            shouldVideoRemainAfterPlay,
            disableClickThrough,
            videoPlayStallTimeout: this.videoPlayStallTimeout,
            startVideoPlayTimeout: this.startVideoPlayTimeout,
            adBreakBufferTimeout: this.adBreakBufferTimeout,
            bufferingSpinnerColor: this.bufferingSpinnerColor,
        };
        this.messageBridge.postMessage({
            eventName: PlayerController_1.PublisherVideoPlayerEvents.START_ADS,
            values,
        });
    }
    /**
     * @param  {IPublisherPlayerState} publisherPlayerState
     * Used by the content player to emit a cross-iframe event to the SDK to set the default ad player state.
     */
    init(publisherPlayerState) {
        const { replayEnabled, muted, volume, autoplay, controls, preload } = publisherPlayerState;
        const values = {
            muted,
            volume,
            autoplay,
            replayEnabled: replayEnabled === true ? true : false,
        };
        if (controls) {
            values.controls = controls;
        }
        if (preload) {
            values.preload = preload;
        }
        this.messageBridge.postMessage({
            eventName: PlayerController_1.PublisherVideoPlayerEvents.INIT,
            values,
        });
    }
    /**
     * Used by the content player to emit a cross-iframe event to the SDK which tells the ad player to pause.
     */
    pause() {
        this.messageBridge.postMessage({
            eventName: PlayerController_1.PublisherVideoPlayerEvents.PAUSE,
        });
    }
    triggerEndCardFlow() {
        this.store.dispatch(player_1.setEndCardsShown(true));
        const state = this.store.getState();
        if (!((state.ads || {}).currentCompanions || [])[0]) {
            window.mraid.useCustomClose(false);
        }
    }
    /**
     * Used by the content player to emit a cross-iframe event to the SDK which tells the ad player to play.
     */
    play() {
        this.messageBridge.postMessage({
            eventName: PlayerController_1.PublisherVideoPlayerEvents.PLAY,
        });
    }
    /**
     * Used by the content player to emit a cross-iframe event to the SDK which tells the ad player to change volume.
     */
    setVolume(volume) {
        const values = { volume };
        this.messageBridge.postMessage({
            eventName: PlayerController_1.PublisherVideoPlayerEvents.SET_VOLUME,
            values,
        });
    }
    /**
     * Used by the content player to emit a cross-iframe event to the SDK which tells the ad player to mute.
     */
    mute(muted) {
        const values = { muted };
        this.messageBridge.postMessage({
            eventName: PlayerController_1.PublisherVideoPlayerEvents.MUTE,
            values,
        });
    }
    /**
     * Used by the content player when the content player wants to remove the ad from the DOM entirely.
     * This call is synchronous so clients do not need to wait for a REQUEST_RESUME callback.
     */
    destroy() {
        this.messageBridge.destroy();
        index_1.unmountPlayer();
    }
    /**
     * Used by the content player to emit a cross-iframe event to the SDK which tells the
     * Ad player to relinquish control and no longer emit events to the publisher.
     * This call is asynchronous since clients are waiting for a REQUEST_RESUME callback.
     */
    cancelAdBreak() {
        this.messageBridge.postMessage({
            eventName: PlayerController_1.PublisherVideoPlayerEvents.DESTROY,
        });
    }
    addListener(eventName, cb) {
        this.messageBridge.addListener(eventName, cb);
        if (eventName === AdVideoPlayerEvents.ERRORED) {
            this.errorCallback = cb;
        }
    }
    /**
     * Provides an event listener for when the player is ready to start receiving control interactions.
     */
    onPlayerReadyListener(fn) {
        this.store.dispatch(player_1.setOnPlayerReadyListener(fn));
    }
    /**
     * Subscribe the content player to events emitted by the ad player.
     */
    addListeners() {
        this.messageBridge.addListener(AdVideoPlayerEvents.REQUEST_RESUME, this.onRequestResume);
    }
}
exports.AdsManager = AdsManager;
