"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const redux_1 = require("redux");
const ads_1 = require("./ads");
const networkStatistics_1 = require("./networkStatistics");
const player_1 = require("./player");
const vast_1 = require("./vast");
const publisherAttributes_1 = require("./publisherAttributes");
// Combine all reducers into a single reducer.
exports.reducers = redux_1.combineReducers({
    ads: ads_1.AdsReducer,
    networkStatistics: networkStatistics_1.NetworkStatisticsReducer,
    player: player_1.PlayerReducer,
    vast: vast_1.VASTReducer,
    publisherAttributes: publisherAttributes_1.PublisherAttributesReducer,
});
