"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
exports.EndCardClose = (props) => {
    return (React.createElement("div", { onClick: props.onClick, id: 'vsdk-endcard-close', "data-testid": 'vsdk-endcard-close', style: {
            width: '12px',
            height: '12px',
            padding: '10px',
            borderRadius: '50%',
            position: 'absolute',
            zIndex: 2,
            top: 15,
            right: 15,
            font: 'bold 20px Arial, sans-serif',
            color: 'white',
            backgroundColor: 'rgba(231, 231, 231, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        } },
        React.createElement("span", null, "\u2715")));
};
