"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const Analytics_1 = require("../../controllers/Analytics");
class EndCardCountdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countdown: 5,
        };
    }
    componentDidMount() {
        try {
            this.countDownInterval();
        }
        catch (e) {
            try {
                window.mraid.useCustomClose(false);
            }
            catch (_a) {
                /* NOOP */
            }
            Analytics_1.analytics.recordEvent({
                [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: Analytics_1.AnalyticsEventType.END_CARD_COUNTDOWN_ERROR,
                [Analytics_1.AnalyticsEventAttribute.ERROR_MESSAGE]: (e || {}).message,
            });
        }
    }
    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }
    render() {
        if (this.state.countdown === 0) {
            return null;
        }
        return (React.createElement("div", { id: 'vsdk-endcard-countdown', "data-testid": 'vsdk-endcard-countdown', style: {
                width: '12px',
                height: '12px',
                padding: '10px',
                borderRadius: '50%',
                position: 'absolute',
                zIndex: 2,
                top: 15,
                right: 15,
                font: 'bold 20px Arial, sans-serif',
                color: 'white',
                backgroundColor: 'rgba(231, 231, 231, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            } }, this.state.countdown > 0 ? (this.state.countdown) : (React.createElement("span", null, "\u2715"))));
    }
    countDownInterval() {
        this.intervalId = setTimeout(() => {
            this.intervalId = setInterval(() => {
                if (this.state.countdown === 1) {
                    Analytics_1.analytics.recordEvent({
                        [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: Analytics_1.AnalyticsEventType.END_CARD_COMPLETE,
                    });
                }
                if (this.state.countdown > 0) {
                    this.countDown();
                }
                else {
                    clearInterval(this.intervalId);
                    this.intervalId = undefined;
                    // show native mobile close button
                    if (window.mraid && window.mraid.useCustomClose) {
                        window.mraid.useCustomClose(false);
                    }
                    else {

                    }
                }
            }, 1000);
        }, 1000);
    }
    countDown() {
        // Only count down when the document is not hidden
        if (document && document.visibilityState === 'hidden') {
            return;
        }
        this.setState((prevState) => ({
            countdown: prevState.countdown - 1,
        }));
    }
}
exports.EndCardCountdown = EndCardCountdown;
