"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PlayerControlComponent;
(function (PlayerControlComponent) {
    PlayerControlComponent["PAUSE_COMPONENT"] = "icons/icon_pause.svg";
    PlayerControlComponent["PLAY_COMPONENT"] = "icons/icon_play.svg";
    PlayerControlComponent["REPLAY_COMPONENT"] = "icons/icon_replay.svg";
    PlayerControlComponent["MUTE_COMPONENT"] = "icons/icon_mute.svg";
    PlayerControlComponent["UNMUTE_COMPONENT"] = "icons/icon_unmute.svg";
})(PlayerControlComponent = exports.PlayerControlComponent || (exports.PlayerControlComponent = {}));
