"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const CompanionTemplates_1 = require("../templates/CompanionTemplates");
const tracking_1 = require("../utils/tracking");
const AmazonVideoAdsError_1 = require("../errors/AmazonVideoAdsError");
const Analytics_1 = require("../controllers/Analytics");
const TrackingEvent_1 = require("../constants/TrackingEvent");
const CreativeCompanion_1 = require("../model/CreativeCompanion");
const isValidHTMLString = (markup) => {
    return markup.indexOf('<') > -1 && markup.indexOf('>') > -1;
};
const createIFrameWithHTML = (parent, html) => {

    const iframe = document.createElement('iframe');
    iframe.sandbox =
        'allow-forms allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation-by-user-activation';
    parent.appendChild(iframe);
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    iframe.style.padding = '0';
    iframe.style.margin = '0';
    iframe.srcdoc = html;
};
exports.renderCompanionInDIV = (ad, div, errorURLTemplates, options) => {
    const { reportError } = options;

    if (ad.apiFramework) {
        reportError(ad, new AmazonVideoAdsError_1.AmazonVideoAdsError(AmazonVideoAdsError_1.ErrorType.AD_LOAD, AmazonVideoAdsError_1.AmazonErrorCode.COMPANION_VAST_UNSUPPORTED_FRAMEWORK, `CompanionAd apiFramework '${ad.apiFramework}' is not supported.`), errorURLTemplates);
        return false;
    }
    const clickTrackingURLs = tracking_1.resolveURLTemplates(ad.companionClickTrackingURLTemplates, {});
    const clickThroughURL = tracking_1.resolveURLTemplates([ad.companionClickThroughURLTemplate], {})[0];
    const trackingEvents = {};
    Object.keys(ad.trackingEvents || {}).forEach((key) => {
        trackingEvents[key] = tracking_1.resolveURLTemplates(ad.trackingEvents[key], {});
    });
    // Check Asset Size Fits
    if (!options.isEndCard &&
        (ad.assetHeight > div.clientHeight || ad.assetWidth > div.clientWidth)) {
        reportError(ad, new AmazonVideoAdsError_1.AmazonVideoAdsError(AmazonVideoAdsError_1.ErrorType.AD_LOAD, AmazonVideoAdsError_1.AmazonErrorCode.COMPANION_NO_AVAILABLE_SPACE, `CompanionAd slot size ${div.clientWidth}x${div.clientHeight} too small for ad asset size ${ad.assetWidth}x${ad.assetHeight}.`), errorURLTemplates);
        return false;
    }
    // TODO: HTMLResource
    // TODO: This is only implementd for end cards. Proper companion support for HTMLResource
    //       still needs to be implemented.
    if (ad.htmlResource) {
        if (!options.isEndCard) {
            reportError(ad, new AmazonVideoAdsError_1.AmazonVideoAdsError(AmazonVideoAdsError_1.ErrorType.AD_LOAD, AmazonVideoAdsError_1.AmazonErrorCode.COMPANION_VAST_UNSUPPORTED_TYPE, `CompanionAd HTMLResource not yet supported.`), errorURLTemplates);
            return false;
        }
        if (ad.htmlResource.endsWith('.html')) {
            fetch(ad.htmlResource)
                .then((response) => response.text())
                .then((text) => {
                if (isValidHTMLString(text)) {
                    createIFrameWithHTML(div, text);
                }
                else {
                    div.innerHTML = '';
                }
            })
                .catch((e) => {
                div.innerHTML = '';
                if (options.onAsyncError) {
                    options.onAsyncError(e);
                }
            });
        }
        else {
            if (isValidHTMLString(ad.htmlResource)) {
                createIFrameWithHTML(div, ad.htmlResource);
            }
            else {
                div.innerHTML = '';
                if (options.onAsyncError) {
                    options.onAsyncError(new Error('Invalid markup string'));
                }
            }
        }
        return true;
    }
    // TODO: IFrameResource
    if (ad.iframeResource) {
        if (!options.isEndCard) {
            console.warn('CompanionAd IFrameResource not yet supported');
            reportError(ad, new AmazonVideoAdsError_1.AmazonVideoAdsError(AmazonVideoAdsError_1.ErrorType.AD_LOAD, AmazonVideoAdsError_1.AmazonErrorCode.COMPANION_VAST_UNSUPPORTED_TYPE, `CompanionAd IFrameResource not yet supported.`), errorURLTemplates);
            return false;
        }
        const SANDBOX = 'allow-forms allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation-by-user-activation';
        const iframeText = (ad.iframeResource || '').indexOf('<iframe') > -1
            ? ad.iframeResource
            : `<iframe src="${ad.iframeResource}" sandbox="${SANDBOX}"></iframe>`;
        div.innerHTML = iframeText.replace('<iframe ', '<iframe style="border:none;padding:0;margin:0;width:100%;height:100%;" ');
        return true;
    }
    // StaticResource
    if (ad.staticResource) {
        console.warn('CompanionController static resource: ', ad);
        const adType = ad.type || '';
        // StaticResource Image
        if (adType.startsWith('image/')) {
            div.innerHTML = CompanionTemplates_1.CompanionTemplates.populateTemplate(options.isEndCard
                ? CompanionTemplates_1.CompanionTemplates.TEMPLATE_STATIC_RESOURCE_IMAGE_END_CARD
                : CompanionTemplates_1.CompanionTemplates.TEMPLATE_STATIC_RESOURCE_IMAGE, {
                altText: ad.altText || '',
                width: (ad.assetWidth || 0).toString(),
                height: (ad.assetHeight || 0).toString(),
                source: ad.staticResource,
                clickTrackingURLs,
                clickThroughURL,
                trackingEvents,
                errorTrackingURLs: tracking_1.resolveURLTemplates(errorURLTemplates, {
                    ERRORCODE: AmazonVideoAdsError_1.VastErrorCode.COMPANION_UNABLE_TO_FETCH,
                    REASON: tracking_1.encodeURIComponentRFC3986(`Unable to load image (${ad.staticResource}).`),
                }),
            });
            return true;
        }
        else if (adType === 'application/x-javascript' ||
            adType === 'text/javascript') {
            div.innerHTML = CompanionTemplates_1.CompanionTemplates.populateTemplate(CompanionTemplates_1.CompanionTemplates.TEMPLATE_STATIC_RESOURCE_JAVASCRIPT, {
                altText: ad.altText || '',
                width: (ad.assetWidth || 0).toString(),
                height: (ad.assetHeight || 0).toString(),
                source: ad.staticResource,
                clickTrackingURLs,
                clickThroughURL,
                trackingEvents,
                errorTrackingURLs: tracking_1.resolveURLTemplates(errorURLTemplates, {
                    ERRORCODE: AmazonVideoAdsError_1.VastErrorCode.COMPANION_UNABLE_TO_FETCH,
                    REASON: tracking_1.encodeURIComponentRFC3986(`Unable to load JavaScript source (${ad.staticResource}).`),
                }),
            });
            return true;
        }
        console.warn("CompanionAd StaticResource type '%s' is not supported", ad.type);
        reportError(ad, new AmazonVideoAdsError_1.AmazonVideoAdsError(AmazonVideoAdsError_1.ErrorType.AD_LOAD, AmazonVideoAdsError_1.AmazonErrorCode.COMPANION_VAST_UNSUPPORTED_TYPE, `CompanionAd StaticResource type \'${ad.type}\' is not supported.`), errorURLTemplates);
        return false;
    }
    reportError(ad, new AmazonVideoAdsError_1.AmazonVideoAdsError(AmazonVideoAdsError_1.ErrorType.AD_LOAD, AmazonVideoAdsError_1.AmazonErrorCode.COMPANION_VAST_UNSUPPORTED_TYPE, `CompanionAd is missing resources.`), errorURLTemplates);
    return false;
};
/**
 * Manages companion ad rendering.
 * @class
 */
class CompanionController {
    constructor(store, props, playerController) {
        this.nextAdIndex = 0;
        this.companionDivs = [];

        if (!props.companionDivs || props.companionDivs.length <= 0) {

            return;
        }
        this.playerController = playerController;
        this.companionDivs = props.companionDivs;
        store.subscribe(() => this.handleStateChange(store.getState()));
    }
    resetNextAdIndex() {
        this.nextAdIndex = 0;
    }
    handleStateChange(state) {
        const { nextAdIndex, currentAd, currentCompanions } = state.ads;
        if (this.nextAdIndex !== nextAdIndex) {
            this.nextAdIndex = nextAdIndex;

            if (currentAd) {

                this.renderCompanions(currentCompanions, currentAd.errorURLTemplates);
            }
            else {

                this.resetNextAdIndex();
            }
        }
    }
    renderCompanions(companionAds, errorURLTemplates) {
        let allRendered = true;
        let someRendered = false;
        let required;
        let lastAd;

        companionAds.forEach((ad) => {

            lastAd = ad;
            // Empty setting can flip to ANY
            if (!required && ad.required === CreativeCompanion_1.Required.ANY) {
                required = CreativeCompanion_1.Required.ANY;
            }
            // Any other setting is overwritten by ALL, which takes precedence
            if (ad.required === CreativeCompanion_1.Required.ALL) {
                required = CreativeCompanion_1.Required.ALL;
            }
            const sizeMatchedDivs = this.companionDivs.filter((div) => ad.width === div.clientWidth && ad.height === div.clientHeight);
            const idMatchedDivs = sizeMatchedDivs.filter((div) => div.id === ad.adSlotID);
            const matchedDivs = idMatchedDivs.length
                ? idMatchedDivs
                : sizeMatchedDivs;

            if (matchedDivs.length) {

                const rendered = this.renderCompanionInDIV(ad, matchedDivs[0], errorURLTemplates);
                someRendered = someRendered || rendered;
                allRendered = allRendered && rendered;
            }
            else {
                allRendered = false;

                Analytics_1.analytics.recordEvent({
                    [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: Analytics_1.AnalyticsEventType.COMPANION_FAILURE,
                    [Analytics_1.AnalyticsEventAttribute.ERROR_MESSAGE]: `CompanionAd found no matching slot for size ${ad.width}x${ad.height}`,
                    [Analytics_1.AnalyticsEventAttribute.ERROR_CODE]: AmazonVideoAdsError_1.AmazonErrorCode.COMPANION_MISSING_SLOT,
                    [Analytics_1.AnalyticsEventAttribute.CREATIVE_ID]: ad.id,
                });
            }
        });
        if ((required === CreativeCompanion_1.Required.ALL && !allRendered) ||
            (required === CreativeCompanion_1.Required.ANY && !someRendered)) {
            this.reportError(lastAd, new AmazonVideoAdsError_1.AmazonVideoAdsError(AmazonVideoAdsError_1.ErrorType.AD_LOAD, AmazonVideoAdsError_1.AmazonErrorCode.COMPANION_VAST_CONSTRAINT_REQUIRED, `CompanionAd 'required = ${required}' constraint was not met.`), errorURLTemplates);
            // Cause player to skip to next linear ad in pod.
            this.playerController.skipCurrentAd();
        }
    }
    renderCompanionInDIV(ad, div, errorURLTemplates) {
        return exports.renderCompanionInDIV(ad, div, errorURLTemplates, {
            reportError: this.reportError.bind(this),
        });
    }
    /**
     * Tracks errors encountered in companions. Replaces Macros if provided in the URLs.
     * @param {CompanionAd} ad the ad that failed.
     * @param {AmazonVideoAdsError} error the error to report.
     * @param {string[]} errorTrackingURLs tracking URLs with macros.
     */
    reportError(ad, error, errorTrackingURLs) {
        const errorCode = error.getVastErrorCode();
        const macroSubstitutions = {
            ERRORCODE: errorCode,
            REASON: tracking_1.encodeURIComponentRFC3986(error.message),
        };
        const urlSubstitutions = { eventName: TrackingEvent_1.TrackingEvent.ERROR };
        Analytics_1.analytics.recordEvent({
            [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: Analytics_1.AnalyticsEventType.COMPANION_FAILURE,
            [Analytics_1.AnalyticsEventAttribute.ERROR_CODE]: error.errorCode,
            [Analytics_1.AnalyticsEventAttribute.ERROR_MESSAGE]: error.message,
            [Analytics_1.AnalyticsEventAttribute.CREATIVE_ID]: ad.id,
        });
        tracking_1.track(errorTrackingURLs, macroSubstitutions, urlSubstitutions);
        // TODO: Post Companion Errors to Message Bridge? ...to OMSDK?
        // this.messageBridge.postMessage({
        //   eventName: AdVideoPlayerEvents.ERRORED,
        //   values: error,
        // });
    }
}
exports.CompanionController = CompanionController;
