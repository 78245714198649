"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BrowserError;
(function (BrowserError) {
    // Thrown when play() is not allowed on the <video> element
    BrowserError["NOT_ALLOWED_ERROR"] = "NotAllowedError";
})(BrowserError = exports.BrowserError || (exports.BrowserError = {}));
var MediaErrorCode;
(function (MediaErrorCode) {
    MediaErrorCode[MediaErrorCode["MEDIA_ERR_ABORTED"] = 1] = "MEDIA_ERR_ABORTED";
    MediaErrorCode[MediaErrorCode["MEDIA_ERR_NETWORK"] = 2] = "MEDIA_ERR_NETWORK";
    MediaErrorCode[MediaErrorCode["MEDIA_ERR_DECODE"] = 3] = "MEDIA_ERR_DECODE";
    MediaErrorCode[MediaErrorCode["MEDIA_ERR_SRC_NOT_SUPPORTED"] = 4] = "MEDIA_ERR_SRC_NOT_SUPPORTED";
})(MediaErrorCode = exports.MediaErrorCode || (exports.MediaErrorCode = {}));
