"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Creative_1 = require("./Creative");
class CreativeNonLinear extends Creative_1.Creative {
    constructor(creativeAttributes = {}) {
        super(creativeAttributes);
        this.type = 'nonlinear';
        this.variations = [];
        this.trackingEvents = {};
    }
}
exports.CreativeNonLinear = CreativeNonLinear;
