"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ViewabilityExtensions;
(function (ViewabilityExtensions) {
    ViewabilityExtensions["LINE_ITEM_ID"] = "LineItemId";
    ViewabilityExtensions["ADVERTISER_ID"] = "AdvertiserId";
    ViewabilityExtensions["ORDER_ID"] = "OrderId";
    ViewabilityExtensions["CREATIVE_ID"] = "CreativeId";
})(ViewabilityExtensions = exports.ViewabilityExtensions || (exports.ViewabilityExtensions = {}));
class Creative {
    constructor(creativeAttributes = {}) {
        this.id = creativeAttributes.id || null;
        this.adId = creativeAttributes.adId || null;
        this.sequence = creativeAttributes.sequence || null;
        this.apiFramework = creativeAttributes.apiFramework || null;
        this.extensions = [];
    }
}
exports.Creative = Creative;
