"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const Analytics_1 = require("../../controllers/Analytics");
const EndCardClose_1 = require("./EndCardClose");
const EndCardCountdown_1 = require("./EndCardCountdown");
const EndCardAd_1 = require("./EndCardAd");
const tracking_1 = require("../../utils/tracking");
class EndCard extends React.Component {
    constructor(props) {
        super(props);
        this.LOG_CONTEXT = '<EndCard />: ';
        this.hasPreloadedEndCard = false;
        this.hasTriggeredCreativeView = false;
        this.hasTriggererErrorPixel = false;
        this.analyticsLimits = {};
        this.onClickEndCard = () => {
            this.triggerAnalytics(Analytics_1.AnalyticsEventType.END_CARD_CLICK_THROUGH);
        };
        this.onClickEndCardClose = () => {
            this.setState({
                isCloseClicked: true,
            });
            this.triggerPause();
            this.props.onEndCardClose();
        };
        this.state = {
            didError: false,
            isCloseClicked: false,
        };
    }
    componentDidMount() {
        if (!this.props.ad || this.state.didError) {
            return;
        }
        try {
            this.preloadEndCard();
        }
        catch (error) {
            this.onErrorCleanup({ error });
        }

    }
    render() {
        if (!this.props.ad || this.state.didError) {
            return null;
        }
        const { iframeResource, staticResource, htmlResource } = this.props.ad;
        if ((iframeResource || '').length === 0 &&
            (staticResource || '').length === 0 &&
            (htmlResource || '').length === 0) {
            return null;
        }
        this.triggerAnalytics(Analytics_1.AnalyticsEventType.END_CARD_START, {
            [Analytics_1.AnalyticsEventAttribute.END_CARD_RESOURCE]: this.getResourceType(),
        });
        const isEndCardHidden = (this.props.isHidden || this.state.didError) &&
            !this.state.isCloseClicked;
        if (!isEndCardHidden) {
            this.triggerCreativeViewPixel();
        }
        return (React.createElement(React.Fragment, null,
            !isEndCardHidden && (React.createElement(EndCardAd_1.EndCardAd, { ad: this.props.ad, onErrorCleanup: this.onErrorCleanup.bind(this), onClick: this.onClickEndCard.bind(this) })),
            this.props.isShownEarly &&
                !this.state.isCloseClicked &&
                !this.props.isLastFrame && (React.createElement(EndCardClose_1.EndCardClose, { onClick: this.onClickEndCardClose.bind(this) })),
            (this.state.isCloseClicked || this.props.isLastFrame) && (React.createElement(EndCardCountdown_1.EndCardCountdown, null))));
    }
    preloadEndCard() {
        if (this.hasPreloadedEndCard) {
            return;
        }
        if ((this.props.ad.staticResource || '').length > 0) {
            const img = new Image();
            img.onload = this.onPreloadSuccess.bind(this);
            img.onerror = this.onPreloadError.bind(this);
            img.src = this.props.ad.staticResource;
        }
        this.hasPreloadedEndCard = true;
    }
    onPreloadSuccess() {

    }
    onPreloadError(error) {
        this.onErrorCleanup({
            error,
            event: Analytics_1.AnalyticsEventType.END_CARD_PRELOAD_ERROR,
        });
    }
    triggerPause() {
        if (!this.props.isLastFrame &&
            !this.props.isPlayerPaused &&
            this.props.onPlayerPlayPause) {
            this.props.onPlayerPlayPause();
        }
    }
    onErrorCleanup({ error, event = Analytics_1.AnalyticsEventType.END_CARD_RENDER_ERROR, }) {

        this.triggerErrorPixel();
        this.setState({ didError: true });
        Analytics_1.analytics.recordEvent({
            [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: event,
            [Analytics_1.AnalyticsEventAttribute.ERROR_MESSAGE]: (error || {}).message,
        });
        this.triggerPause();
        if (window.mraid && typeof window.mraid.useCustomClose === 'function') {
            window.mraid.useCustomClose(false);
        }
    }
    triggerCreativeViewPixel() {
        if (this.hasTriggeredCreativeView ||
            (this.props.ad.trackingEvents.creativeView || []).length === 0) {
            return;
        }
        this.hasTriggeredCreativeView = true;
        this.props.onEndCardRendered();
        (this.props.ad.trackingEvents.creativeView || []).forEach((url) => {
            new Image().src = url;

        });
    }
    triggerErrorPixel() {
        if (this.hasTriggererErrorPixel ||
            (this.props.errorURLTemplates || []).length === 0) {
            return;
        }
        this.hasTriggererErrorPixel = true;
        const urls = tracking_1.resolveURLTemplates(this.props.errorURLTemplates, {});
        urls.forEach((url) => {
            new Image().src = url;

        });
    }
    triggerAnalytics(eventType, options) {
        if (this.analyticsLimits[eventType]) {
            return;
        }
        this.analyticsLimits[eventType] = true;
        let event = {
            [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: eventType,
        };
        if (typeof options === 'object') {
            event = Object.assign({}, event, options);
        }
        Analytics_1.analytics.recordEvent(event);
    }
    getResourceType() {
        const { iframeResource, staticResource, htmlResource } = this.props.ad;
        if ((iframeResource || '').length > 0) {
            return 'iframe';
        }
        else if ((htmlResource || '').length > 0) {
            return 'html';
        }
        else if ((staticResource || '').length > 0) {
            return 'static';
        }
        return 'unknown';
    }
}
exports.EndCard = EndCard;
