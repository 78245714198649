"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vast_1 = require("../actions/vast");
const ads_1 = require("../actions/ads");
exports.initialState = {
    vastUrl: '',
    adRequestDuration: null,
    error: null,
    loading: false,
    vastBody: null,
    vastWrapperRequestQueue: [],
};
/**
 * Updates the VAST part of the Redux State.
 * @param {IVASTState} state Current VAST state.
 * @param {VASTAction} action Redux Action requesting a state update.
 * @return {IVASTState} Updated state as a result of the action.
 */
exports.VASTReducer = (state = exports.initialState, action) => {
    switch (action.type) {
        case vast_1.VASTActionTypes.FETCH_VAST_REQUEST:
            const requestAction = action;
            return Object.assign({}, state, { vastUrl: requestAction.payload.url, loading: true });
        case vast_1.VASTActionTypes.FETCH_VAST_SUCCESS:
            const successAction = action;
            return Object.assign({}, state, { adRequestDuration: successAction.payload.adRequestDuration, error: null, loading: false, vastBody: successAction.payload.vast });
        case vast_1.VASTActionTypes.FETCH_VAST_FAILURE:
            const failureAction = action;
            return Object.assign({}, state, { adRequestDuration: failureAction.payload.adRequestDuration, error: failureAction.error, loading: false });
        case vast_1.VASTActionTypes.PUSH_VAST_WRAPPER_REQUEST_URL:
            const pushAction = action;
            const { url } = pushAction.payload;
            if (typeof url !== 'string' || url === '') {
                return state;
            }
            return Object.assign({}, state, { vastWrapperRequestQueue: [
                    ...state.vastWrapperRequestQueue,
                    pushAction.payload.url,
                ] });
        case ads_1.AdActionTypes.PREPARE_FOR_AD_BREAK:
            return exports.initialState;
        default:
            return state;
    }
};
