"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const player_1 = require("./player");
var AdActionTypes;
(function (AdActionTypes) {
    AdActionTypes["PLAY_NEXT_AD"] = "PLAY_NEXT_AD";
    AdActionTypes["PREPARE_FOR_AD_BREAK"] = "PREPARE_FOR_AD_BREAK";
    AdActionTypes["SET_AD_DURATION"] = "SET_AD_DURATION";
})(AdActionTypes = exports.AdActionTypes || (exports.AdActionTypes = {}));
/**
 * Signals to Redux that a new Ad should be played.
 * @param {VASTResponse} vast The current VAST state.
 * @return {IPlayNextAd} Redux Action.
 */
exports.playNextAd = (vastBody, bitrate) => {
    return {
        payload: {
            bitrate,
            vastBody,
        },
        type: AdActionTypes.PLAY_NEXT_AD,
    };
};
exports.prepareForAdBreak = () => {
    return {
        type: AdActionTypes.PREPARE_FOR_AD_BREAK,
    };
};
exports.setAdDuration = (duration) => {
    return {
        type: AdActionTypes.SET_AD_DURATION,
        payload: {
            duration,
        },
    };
};
/**
 * Reads the VAST Redux state and passes it to the playNextAd Action.
 * @return {void}
 */
exports.selectNextAd = () => {
    return (dispatch, getState) => {
        dispatch(player_1.resetPlayerLifecycle());
        dispatch(exports.playNextAd(getState().vast.vastBody, getState().networkStatistics.bitrate));
    };
};
