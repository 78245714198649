"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NetworkStatisticsActionTypes;
(function (NetworkStatisticsActionTypes) {
    NetworkStatisticsActionTypes["UPDATE_BITRATE"] = "UPDATE_BITRATE";
})(NetworkStatisticsActionTypes = exports.NetworkStatisticsActionTypes || (exports.NetworkStatisticsActionTypes = {}));
/**
 * Signals to Redux that the bitrate should be updated.
 * @param {number} bitrate The new bitrate.
 * @return {IUpdateBitrate} Redux Action.
 */
exports.updateBitrate = (bitrate) => {
    return {
        payload: {
            bitrate,
        },
        type: NetworkStatisticsActionTypes.UPDATE_BITRATE,
    };
};
