"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Source_1 = require("../model/Source");
const EnvironmentConfig_1 = require("./EnvironmentConfig");
/**
 * Object defining feature-level kill switches.
 *
 * @summary
 * - true = kill switch is on and the feature should be disabled
 * - false = kill switch is off and the feature should be enabled
 */
const KILL_SWITCHES = Object.freeze({
    OMSDK: {
        [Source_1.SourceEnum.WEB]: {
            [EnvironmentConfig_1.ReleaseEnvEnum.LOCAL]: false,
            [EnvironmentConfig_1.ReleaseEnvEnum.BETA]: false,
            [EnvironmentConfig_1.ReleaseEnvEnum.PROD]: false,
        },
        [Source_1.SourceEnum.MOBILE]: {
            [EnvironmentConfig_1.ReleaseEnvEnum.LOCAL]: false,
            [EnvironmentConfig_1.ReleaseEnvEnum.BETA]: false,
            [EnvironmentConfig_1.ReleaseEnvEnum.PROD]: false,
        },
    },
    ANALYTICS_RECORD_EVENT: {
        [Source_1.SourceEnum.WEB]: {
            [EnvironmentConfig_1.ReleaseEnvEnum.LOCAL]: false,
            [EnvironmentConfig_1.ReleaseEnvEnum.BETA]: false,
            [EnvironmentConfig_1.ReleaseEnvEnum.PROD]: false,
        },
        [Source_1.SourceEnum.MOBILE]: {
            [EnvironmentConfig_1.ReleaseEnvEnum.LOCAL]: false,
            [EnvironmentConfig_1.ReleaseEnvEnum.BETA]: false,
            [EnvironmentConfig_1.ReleaseEnvEnum.PROD]: false,
        },
    },
    VPAID: {
        [EnvironmentConfig_1.ReleaseEnvEnum.LOCAL]: false,
        [EnvironmentConfig_1.ReleaseEnvEnum.BETA]: false,
        [EnvironmentConfig_1.ReleaseEnvEnum.PROD]: false,
    },
});
exports.default = KILL_SWITCHES;
