"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PublisherAttributesActionTypes;
(function (PublisherAttributesActionTypes) {
    PublisherAttributesActionTypes["SET_SOURCE"] = "SET_SOURCE";
    PublisherAttributesActionTypes["SET_PUBLISHER_TYPE"] = "SET_PUBLISHER_TYPE";
    PublisherAttributesActionTypes["SET_PUBLISHER_APP_ID"] = "SET_PUBLISHER_APP_ID";
    PublisherAttributesActionTypes["SET_PUBLISHER_UUID"] = "SET_PUBLISHER_UUID";
    PublisherAttributesActionTypes["SET_SOURCE_ID"] = "SET_SOURCE_ID";
    PublisherAttributesActionTypes["SET_INTEGRATOR_VERSION"] = "SET_INTEGRATOR_VERSION";
    PublisherAttributesActionTypes["SET_BID_ID"] = "SET_BID_ID";
    PublisherAttributesActionTypes["SET_HOST_NAME"] = "SET_HOST_NAME";
    PublisherAttributesActionTypes["SET_PRICE_POINT"] = "SET_PRICE_POINT";
    PublisherAttributesActionTypes["SET_AD_SERVER_TYPE"] = "SET_AD_SERVER_TYPE";
    PublisherAttributesActionTypes["SET_AD_SERVER_AD_UNIT_ID"] = "SET_AD_SERVER_AD_UNIT_ID";
    PublisherAttributesActionTypes["SET_APS_AD_UNIT_ID"] = "SET_APS_AD_UNIT_ID";
    PublisherAttributesActionTypes["SET_TARGETING_MAP"] = "SET_TARGETING_MAP";
    PublisherAttributesActionTypes["SET_BROWSER_PLATFORM"] = "SET_BROWSER_PLATFORM";
    PublisherAttributesActionTypes["SET_BROWSER_OS_VERSION"] = "SET_BROWSER_OS_VERSION";
    PublisherAttributesActionTypes["SET_MOBILE_SDK_VERSION"] = "SET_MOBILE_SDK_VERSION";
    PublisherAttributesActionTypes["SET_MOBILE_DEVICE_TYPE"] = "SET_MOBILE_DEVICE_TYPE";
    PublisherAttributesActionTypes["SET_MOBILE_DEVICE_PLATFORM"] = "SET_MOBILE_DEVICE_PLATFORM";
    PublisherAttributesActionTypes["SET_MOBILE_DEVICE_OS_VERSION"] = "SET_MOBILE_DEVICE_OS_VERSION";
    PublisherAttributesActionTypes["SET_DEAL_ID"] = "SET_DEAL_ID";
    PublisherAttributesActionTypes["SET_END_CARDS_ENABLED"] = "SET_END_CARDS_ENABLED";
})(PublisherAttributesActionTypes = exports.PublisherAttributesActionTypes || (exports.PublisherAttributesActionTypes = {}));
exports.setSource = (source) => {
    return {
        payload: { source },
        type: PublisherAttributesActionTypes.SET_SOURCE,
    };
};
exports.setPublisherType = (publisherType) => {
    return {
        payload: { publisherType },
        type: PublisherAttributesActionTypes.SET_PUBLISHER_TYPE,
    };
};
exports.setPublisherAppId = (publisherAppId) => {
    return {
        payload: { publisherAppId },
        type: PublisherAttributesActionTypes.SET_PUBLISHER_APP_ID,
    };
};
exports.setPublisherUUID = (publisherUUID) => {
    return {
        payload: { publisherUUID },
        type: PublisherAttributesActionTypes.SET_PUBLISHER_UUID,
    };
};
exports.setSourceId = (sourceId) => {
    return {
        payload: { sourceId },
        type: PublisherAttributesActionTypes.SET_SOURCE_ID,
    };
};
exports.setIntegratorVersion = (integratorVersion) => {
    return {
        payload: { integratorVersion },
        type: PublisherAttributesActionTypes.SET_INTEGRATOR_VERSION,
    };
};
exports.setEndCardsEnabled = (endCardsEnabled) => ({
    payload: { endCardsEnabled },
    type: PublisherAttributesActionTypes.SET_END_CARDS_ENABLED,
});
exports.setBidId = (bidId) => {
    return {
        payload: { bidId },
        type: PublisherAttributesActionTypes.SET_BID_ID,
    };
};
exports.setHostName = (hostName) => {
    return {
        payload: { hostName },
        type: PublisherAttributesActionTypes.SET_HOST_NAME,
    };
};
exports.setPricePoint = (pricePoint) => {
    return {
        payload: { pricePoint },
        type: PublisherAttributesActionTypes.SET_PRICE_POINT,
    };
};
exports.setAdServerType = (adServerType) => {
    return {
        payload: { adServerType },
        type: PublisherAttributesActionTypes.SET_AD_SERVER_TYPE,
    };
};
exports.setAdServerAdUnitId = (adServerAdUnitId) => {
    return {
        payload: { adServerAdUnitId },
        type: PublisherAttributesActionTypes.SET_AD_SERVER_AD_UNIT_ID,
    };
};
exports.setAPSAdUnitId = (apsAdUnitId) => {
    return {
        payload: { apsAdUnitId },
        type: PublisherAttributesActionTypes.SET_APS_AD_UNIT_ID,
    };
};
exports.setTargetingMap = (targetingMap) => {
    return {
        payload: { targetingMap },
        type: PublisherAttributesActionTypes.SET_TARGETING_MAP,
    };
};
exports.setBrowserOSVersion = (browserOSVersion) => {
    return {
        payload: { browserOSVersion },
        type: PublisherAttributesActionTypes.SET_BROWSER_OS_VERSION,
    };
};
exports.setBrowserPlatform = (browserPlatform) => {
    return {
        payload: { browserPlatform },
        type: PublisherAttributesActionTypes.SET_BROWSER_PLATFORM,
    };
};
exports.setMobileSDKVersion = (mobileSDKVersion) => {
    return {
        payload: { mobileSDKVersion },
        type: PublisherAttributesActionTypes.SET_MOBILE_SDK_VERSION,
    };
};
exports.setMobileDeviceType = (mobileDeviceType) => {
    return {
        payload: { mobileDeviceType },
        type: PublisherAttributesActionTypes.SET_MOBILE_DEVICE_TYPE,
    };
};
exports.setMobileDevicePlatform = (mobileDevicePlatform) => {
    return {
        payload: { mobileDevicePlatform },
        type: PublisherAttributesActionTypes.SET_MOBILE_DEVICE_PLATFORM,
    };
};
exports.setMobileDeviceOSVersion = (mobileDeviceOSVersion) => {
    return {
        payload: { mobileDeviceOSVersion },
        type: PublisherAttributesActionTypes.SET_MOBILE_DEVICE_OS_VERSION,
    };
};
exports.setDealId = (dealId) => {
    return {
        payload: { dealId },
        type: PublisherAttributesActionTypes.SET_DEAL_ID,
    };
};
