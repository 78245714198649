"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class NonLinearAd {
    constructor() {
        this.id = null;
        this.width = 0;
        this.height = 0;
        this.expandedWidth = 0;
        this.expandedHeight = 0;
        this.scalable = true;
        this.maintainAspectRatio = true;
        this.minSuggestedDuration = 0;
        this.apiFramework = 'static';
        this.type = null;
        this.staticResource = null;
        this.htmlResource = null;
        this.iframeResource = null;
        this.nonlinearClickThroughURLTemplate = null;
        this.nonlinearClickTrackingURLTemplates = [];
        this.adParameters = null;
    }
}
exports.NonLinearAd = NonLinearAd;
