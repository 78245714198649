"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const CompanionAd_1 = require("../../model/CompanionAd");
const CreativeCompanion_1 = require("../../model/CreativeCompanion");
const CreativeParser_1 = require("./CreativeParser");
/**
 * Provides methods to parse a VAST CompanionAd Element.
 * @class CreativeCompanionParser
 */
class CreativeCompanionParser extends CreativeParser_1.CreativeParser {
    /**
     * Creates an instance of CreativeCompanionParser.
     */
    constructor() {
        super();
    }
    /**
     * Parses a CompanionAd.
     * @param {Element} creativeElement the VAST CompanionAd element to parse.
     * @param {CreativeAttributes} creativeAttributes The attributes of the CompanionAd (optional).
     * @return {CreativeCompanion} the parsed CreativeCompanion
     */
    parse(creativeElement, creativeAttributes) {
        const creative = new CreativeCompanion_1.CreativeCompanion(creativeAttributes);
        const required = (creativeElement.getAttribute('required') || 'none').toLowerCase();
        this.parserUtils
            .childrenByName(creativeElement, 'Companion')
            .forEach((companionResource) => {
            const companionAd = new CompanionAd_1.CompanionAd();
            companionAd.required = (companionResource.getAttribute('required') || required).toLowerCase();
            companionAd.apiFramework =
                companionResource.getAttribute('apiFramework') || '';
            companionAd.id = companionResource.getAttribute('id') || '';
            companionAd.width = parseInt(companionResource.getAttribute('width'), 10);
            companionAd.height = parseInt(companionResource.getAttribute('height'), 10);
            companionAd.assetWidth =
                parseInt(companionResource.getAttribute('assetWidth'), 10) ||
                    companionAd.width;
            companionAd.assetHeight =
                parseInt(companionResource.getAttribute('assetHeight'), 10) ||
                    companionAd.height;
            companionAd.adSlotID = companionResource.getAttribute('adSlotID') || '';
            companionAd.companionClickTrackingURLTemplates = [];
            this.parserUtils
                .childrenByName(companionResource, 'HTMLResource')
                .forEach((htmlElement) => {
                companionAd.type = (htmlElement.getAttribute('creativeType') || 'text/html').toLowerCase();
                companionAd.htmlResource =
                    this.parserUtils.parseNodeText(htmlElement);
            });
            this.parserUtils
                .childrenByName(companionResource, 'IFrameResource')
                .forEach((iframeElement) => {
                companionAd.type = (iframeElement.getAttribute('creativeType') || '').toLowerCase();
                companionAd.iframeResource =
                    this.parserUtils.parseNodeText(iframeElement);
            });
            this.parserUtils
                .childrenByName(companionResource, 'StaticResource')
                .forEach((staticElement) => {
                companionAd.type = (staticElement.getAttribute('creativeType') || '').toLowerCase();
                this.parserUtils
                    .childrenByName(companionResource, 'AltText')
                    .forEach((child) => {
                    companionAd.altText = this.parserUtils.parseNodeText(child);
                });
                companionAd.staticResource =
                    this.parserUtils.parseNodeText(staticElement);
            });
            this.parserUtils
                .childrenByName(companionResource, 'TrackingEvents')
                .forEach((trackingEventsElement) => {
                this.parserUtils
                    .childrenByName(trackingEventsElement, 'Tracking')
                    .forEach((trackingElement) => {
                    const eventName = trackingElement.getAttribute('event');
                    const trackingURLTemplate = this.parserUtils.parseNodeText(trackingElement);
                    if (eventName && trackingURLTemplate) {
                        if (!companionAd.trackingEvents[eventName]) {
                            companionAd.trackingEvents[eventName] = [];
                        }
                        companionAd.trackingEvents[eventName].push(trackingURLTemplate);
                    }
                });
            });
            this.parserUtils
                .childrenByName(companionResource, 'CompanionClickTracking')
                .forEach((clickTrackingElement) => {
                companionAd.companionClickTrackingURLTemplates.push(this.parserUtils.parseNodeText(clickTrackingElement));
            });
            companionAd.companionClickThroughURLTemplate =
                this.parserUtils.parseNodeText(this.parserUtils.childByName(companionResource, 'CompanionClickThrough'));
            creative.variations.push(companionAd);
        });
        return creative;
    }
}
exports.CreativeCompanionParser = CreativeCompanionParser;
