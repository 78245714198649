"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var VPAIDEvents;
(function (VPAIDEvents) {
    VPAIDEvents["AdLoaded"] = "AdLoaded";
    VPAIDEvents["AdStarted"] = "AdStarted";
    VPAIDEvents["AdStopped"] = "AdStopped";
    VPAIDEvents["AdSkipped"] = "AdSkipped";
    VPAIDEvents["AdSkippableStateChange"] = "AdSkippableStateChange";
    VPAIDEvents["AdSizeChange"] = "AdSizeChange";
    VPAIDEvents["AdLinearChange"] = "AdLinearChange";
    VPAIDEvents["AdDurationChange"] = "AdDurationChange";
    VPAIDEvents["AdExpandedChange"] = "AdExpandedChange";
    VPAIDEvents["AdRemainingTimeChange"] = "AdRemainingTimeChange";
    VPAIDEvents["AdVolumeChange"] = "AdVolumeChange";
    VPAIDEvents["AdImpression"] = "AdImpression";
    VPAIDEvents["AdVideoStart"] = "AdVideoStart";
    VPAIDEvents["AdVideoFirstQuartile"] = "AdVideoFirstQuartile";
    VPAIDEvents["AdVideoMidpoint"] = "AdVideoMidpoint";
    VPAIDEvents["AdVideoThirdQuartile"] = "AdVideoThirdQuartile";
    VPAIDEvents["AdVideoComplete"] = "AdVideoComplete";
    VPAIDEvents["AdClickThru"] = "AdClickThru";
    VPAIDEvents["AdInteraction"] = "AdInteraction";
    VPAIDEvents["AdUserAcceptInvitation"] = "AdUserAcceptInvitation";
    VPAIDEvents["AdUserMinimize"] = "AdUserMinimize";
    VPAIDEvents["AdUserClose"] = "AdUserClose";
    VPAIDEvents["AdPaused"] = "AdPaused";
    VPAIDEvents["AdPlaying"] = "AdPlaying";
    VPAIDEvents["AdLog"] = "AdLog";
    VPAIDEvents["AdError"] = "AdError";
})(VPAIDEvents = exports.VPAIDEvents || (exports.VPAIDEvents = {}));
