"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Creative_1 = require("./Creative");
var Required;
(function (Required) {
    Required["ANY"] = "any";
    Required["ALL"] = "all";
    Required["NONE"] = "none";
})(Required = exports.Required || (exports.Required = {}));
class CreativeCompanion extends Creative_1.Creative {
    constructor(creativeAttributes = {}) {
        super(creativeAttributes);
        this.type = 'companion';
        this.variations = [];
    }
}
exports.CreativeCompanion = CreativeCompanion;
