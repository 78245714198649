"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// TODO: Use IntersectionObserver to detect when companion is viewable,
//       before firing creativeView tracking events.
// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
class CompanionTemplates {
    static populateTrackingPixelAdd(url) {
        const elementId = 'pixel-' + Math.round(Math.random() * 1.0e8).toString();
        return CompanionTemplates.TEMPLATE_PIXEL.replace(CompanionTemplates.MACRO_SOURCE, url).replace(CompanionTemplates.MACRO_ID, elementId);
    }
    static populateTrackingPixelsAdd(urls) {
        return (urls || [])
            .map((url) => this.populateTrackingPixelAdd(url))
            .join(';');
    }
}
CompanionTemplates.TEMPLATE_STATIC_RESOURCE_IMAGE = "\
<iframe \
 style=\"border:0;display:block;margin:0;width:100%;height:100%;overflow:hidden\"\
 scrolling=\"no\"\
 sandbox=\"allow-scripts allow-popups allow-popups-to-escape-sandbox\"\
 srcdoc=\"\
 <!DOCTYPE html><html><head></head>\
 <body style='margin:0;width:100%;height:100%;overflow:hidden'>\
 <a href='%%clickThroughURL%%' style='display:inline-flex;text-decoration:none' onclick='%%clickTrackingPixels%%;' target='_blank'>\
 <img src='%%src%%' alt='%%altText%%' style='border:0;margin:0'\
 width='%%width%%'\
 height='%%height%%'\
 onload='\
this.onload=null;\
%%creativeViewPixels%%\
'\
 onerror='\
this.onerror=null;\
%%errorTrackingPixels%%\
'/></a>\
</body></html>\"\
</iframe>\
";
CompanionTemplates.TEMPLATE_STATIC_RESOURCE_IMAGE_END_CARD = "\
<iframe \
style=\"border:0;display:block;margin:0;width:100%;height:100%;overflow:hidden;display:flex;align-items:center;justify-content:center;\"\
scrolling=\"no\"\
sandbox=\"allow-scripts allow-popups allow-same-origin allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation\"\
srcdoc=\"\
<!DOCTYPE html><html style='height: 100%;'><head></head>\
<body style='height:100%;width:100%;margin:0;padding:0;'>\
<a style='display:inline-flex;text-decoration:none;height:100%;width:100%;' \
  onclick='window.mraid && window.mraid.open ? window.mraid.open(`%%clickThroughURL%%`) : window.open(`%%clickThroughURL%%`);%%clickTrackingPixels%%;' target='_blank'>\
<div style='margin:0;width:100%;height:100%;overflow:hidden;display:flex;align-items:center;justify-content:center;'>\
<img src='%%src%%' alt='%%altText%%' style='border:0;margin:0;max-height:100%;max-width:100%;object-fit:contain;'\
width='%%width%%'\
height='%%height%%'\
onload='\
this.onload=null;\
'\
onerror='\
this.onerror=null;\
%%errorTrackingPixels%%\
'/>\
</div>\
</a>\
</body>\
</html>\"\
</iframe>\
";
CompanionTemplates.TEMPLATE_STATIC_RESOURCE_JAVASCRIPT = "\
<iframe \
style=\"border:0;display:block;margin:0;width:100%;height:100%;overflow:hidden\"\
scrolling=\"no\"\
sandbox=\"allow-scripts allow-popups allow-popups-to-escape-sandbox\"\
alt='%%altText%%' \
srcdoc=\"\
<!DOCTYPE html><html><head></head>\
<body style='margin:0;width:100%;height:100%;overflow:hidden'>\
<script src='%%src%%' \
onload='\
this.onload=null;\
%%creativeViewPixels%%\
' \
onerror='\
this.onerror=null;\
%%errorTrackingPixels%%\
'></script>\
</body></html>\"\
</iframe>\
";
CompanionTemplates.TEMPLATE_PIXEL = '\
setTimeout(() => {\
const img = document.createElement(`img`);\
img.src = `%%src%%`;\
img.height = `1`;\
img.width = `1`;\
img.id = `%%id%%`;\
document.body.appendChild(img);\
}, 0)';
CompanionTemplates.populateTemplate = (template, props) => {
    const CREATIVE_VIEW_KEY = 'creativeView';
    return template
        .replace(new RegExp(CompanionTemplates.MACRO_ALT_TEXT, 'g'), props.altText)
        .replace(new RegExp(CompanionTemplates.MACRO_HEIGHT, 'g'), props.height)
        .replace(new RegExp(CompanionTemplates.MACRO_WIDTH, 'g'), props.width)
        .replace(new RegExp(CompanionTemplates.MACRO_SOURCE, 'g'), props.source)
        .replace(new RegExp(CompanionTemplates.MACRO_CLICK_THROUGH_URL, 'g'), props.clickThroughURL)
        .replace(new RegExp(CompanionTemplates.MACRO_CLICK_TRACKING_PIXELS, 'g'), CompanionTemplates.populateTrackingPixelsAdd(props.clickTrackingURLs))
        .replace(new RegExp(CompanionTemplates.MACRO_ERROR_TRACKING_PIXELS, 'g'), CompanionTemplates.populateTrackingPixelsAdd(props.errorTrackingURLs))
        .replace(new RegExp(CompanionTemplates.MACRO_CREATIVE_VIEW_PIXELS, 'g'), CompanionTemplates.populateTrackingPixelsAdd(props.trackingEvents[CREATIVE_VIEW_KEY]));
};
CompanionTemplates.MACRO_ALT_TEXT = '%%altText%%';
CompanionTemplates.MACRO_HEIGHT = '%%height%%';
CompanionTemplates.MACRO_WIDTH = '%%width%%';
CompanionTemplates.MACRO_SOURCE = '%%src%%';
CompanionTemplates.MACRO_CLICK_TRACKING_PIXELS = '%%clickTrackingPixels%%';
CompanionTemplates.MACRO_CLICK_THROUGH_URL = '%%clickThroughURL%%';
CompanionTemplates.MACRO_CREATIVE_VIEW_PIXELS = '%%creativeViewPixels%%';
CompanionTemplates.MACRO_ERROR_TRACKING_PIXELS = '%%errorTrackingPixels%%';
CompanionTemplates.MACRO_ID = '%%id%%';
exports.CompanionTemplates = CompanionTemplates;
