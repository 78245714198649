"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AmazonErrorCode;
(function (AmazonErrorCode) {
    // Network errors
    AmazonErrorCode["AD_REQUEST_NETWORK_ERROR"] = "AD_REQUEST_NETWORK_ERROR";
    AmazonErrorCode["AD_REQUEST_TIMEOUT_ERROR"] = "AD_REQUEST_TIMEOUT_ERROR";
    AmazonErrorCode["AD_REQUEST_EMPTY_VAST_ERROR"] = "AD_REQUEST_EMPTY_VAST_ERROR";
    AmazonErrorCode["AD_REQUEST_GENERAL_ERROR"] = "AD_REQUEST_GENERAL_ERROR";
    AmazonErrorCode["AD_REQUEST_MISSING_REQUIRED_FIELD_ERROR"] = "AD_REQUEST_MISSING_REQUIRED_FIELD_ERROR";
    AmazonErrorCode["AD_REQUEST_INVALID_FIELD_ERROR"] = "AD_REQUEST_INVALID_FIELD_ERROR";
    AmazonErrorCode["AD_REQUEST_MIXED_CONTENT_ERROR"] = "AD_REQUEST_MIXED_CONTENT_ERROR";
    // XML errors
    AmazonErrorCode["SCHEMA_VALIDATION_ERROR"] = "SCHEMA_VALIDATION_ERROR";
    AmazonErrorCode["UNSUPPORTED_VAST_VERSION_ERROR"] = "UNSUPPORTED_VAST_VERSION_ERROR";
    AmazonErrorCode["XML_PARSING_ERROR"] = "XML_PARSING_ERROR";
    // Video errors
    AmazonErrorCode["UNEXPECTED_AD_LINEARITY"] = "UNEXPECTED_AD_LINEARITY";
    AmazonErrorCode["VIDEO_PLAYER_EXPECTING_DIFFERENT_DURATION"] = "VIDEO_PLAYER_EXPECTING_DIFFERENT_DURATION";
    // Wrapper errors
    AmazonErrorCode["WRAPPER_DUPLICATE_URI"] = "WRAPPER_DUPLICATE_URL";
    AmazonErrorCode["WRAPPER_LIMIT_REACHED"] = "WRAPPER_LIMIT_REACHED";
    AmazonErrorCode["WRAPPER_MISSING_RESPONSE"] = "WRAPPER_MISSING_RESPONSE";
    AmazonErrorCode["WRAPPER_URI_TIMEOUT"] = "WRAPPER_URI_TIMEOUT";
    AmazonErrorCode["XML_WRAPPER_PARSING_ERROR"] = "XML_WRAPPER_PARSING_ERROR";
    // Linear media file errors
    AmazonErrorCode["MEDIA_FILE_BUFFERING_TIMEOUT"] = "MEDIA_FILE_BUFFERING_TIMEOUT";
    AmazonErrorCode["MEDIA_FILE_LOAD_TIMEOUT"] = "MEDIA_FILE_LOAD_TIMEOUT";
    AmazonErrorCode["MEDIA_FILE_NOT_FOUND"] = "MEDIA_FILE_NOT_FOUND";
    AmazonErrorCode["MEDIA_FILE_NOT_SUPPORTED"] = "MEDIA_FILE_NOT_SUPPORTED";
    AmazonErrorCode["MEDIA_PLAYBACK_ERROR"] = "MEDIA_PLAYBACK_ERROR";
    AmazonErrorCode["MEDIA_FILE_UNPLAYABLE"] = "MEDIA_FILE_UNPLAYABLE";
    // Companion errors
    AmazonErrorCode["COMPANION_VAST_CONSTRAINT_REQUIRED"] = "COMPANION_VAST_CONSTRAINT_REQUIRED";
    AmazonErrorCode["COMPANION_VAST_UNSUPPORTED_TYPE"] = "COMPANION_VAST_UNSUPPORTED_TYPE";
    AmazonErrorCode["COMPANION_VAST_UNSUPPORTED_FRAMEWORK"] = "COMPANION_VAST_UNSUPPORTED_FRAMEWORK";
    AmazonErrorCode["COMPANION_NO_AVAILABLE_SPACE"] = "COMPANION_NO_SPACE_AVAILABLE";
    AmazonErrorCode["COMPANION_MISSING_SLOT"] = "COMPANION_MISSING_SLOT";
    // VPAID errors
    AmazonErrorCode["VPAID_GENERAL"] = "VPAID_GENERAL";
    // Miscellaneous errors
    AmazonErrorCode["AD_BREAK_TIMEOUT"] = "AD_BREAK_TIMEOUT";
})(AmazonErrorCode = exports.AmazonErrorCode || (exports.AmazonErrorCode = {}));
var VastErrorCode;
(function (VastErrorCode) {
    // XML errors
    VastErrorCode[VastErrorCode["PARSING_GENERAL_ERROR"] = 100] = "PARSING_GENERAL_ERROR";
    VastErrorCode[VastErrorCode["PARSING_VALIDATION_ERROR"] = 101] = "PARSING_VALIDATION_ERROR";
    VastErrorCode[VastErrorCode["PARSING_UNSUPPORTED_VERSION_ERROR"] = 102] = "PARSING_UNSUPPORTED_VERSION_ERROR";
    // Video errors
    VastErrorCode[VastErrorCode["VIDEO_PLAYER_LINEARITY_ERROR"] = 201] = "VIDEO_PLAYER_LINEARITY_ERROR";
    VastErrorCode[VastErrorCode["VIDEO_PLAYER_DURATION_ERROR"] = 202] = "VIDEO_PLAYER_DURATION_ERROR";
    // Wrapper errors
    VastErrorCode[VastErrorCode["UNWRAP_GENERAL_ERROR"] = 300] = "UNWRAP_GENERAL_ERROR";
    VastErrorCode[VastErrorCode["UNWRAP_URI_UNAVAILABLE_ERROR"] = 301] = "UNWRAP_URI_UNAVAILABLE_ERROR";
    VastErrorCode[VastErrorCode["UNWRAP_LIMIT_REACHED_ERROR"] = 302] = "UNWRAP_LIMIT_REACHED_ERROR";
    VastErrorCode[VastErrorCode["UNWRAP_NO_ADS_ERROR"] = 303] = "UNWRAP_NO_ADS_ERROR";
    // Linear media file errors
    VastErrorCode[VastErrorCode["LINEAR_GENERAL_ERROR"] = 400] = "LINEAR_GENERAL_ERROR";
    VastErrorCode[VastErrorCode["LINEAR_NOT_FOUND_ERROR"] = 401] = "LINEAR_NOT_FOUND_ERROR";
    VastErrorCode[VastErrorCode["LINEAR_TIMEOUT_ERROR"] = 402] = "LINEAR_TIMEOUT_ERROR";
    VastErrorCode[VastErrorCode["LINEAR_NOT_SUPPORTED_ERROR"] = 403] = "LINEAR_NOT_SUPPORTED_ERROR";
    VastErrorCode[VastErrorCode["LINEAR_DISPLAY_ERROR"] = 405] = "LINEAR_DISPLAY_ERROR";
    // Companions
    VastErrorCode[VastErrorCode["COMPANION_GENERAL_ERROR"] = 600] = "COMPANION_GENERAL_ERROR";
    VastErrorCode[VastErrorCode["COMPANION_NO_AVAILABLE_SPACE"] = 601] = "COMPANION_NO_AVAILABLE_SPACE";
    VastErrorCode[VastErrorCode["COMPANION_UNABLE_TO_DISPLAY_REQUIRED"] = 602] = "COMPANION_UNABLE_TO_DISPLAY_REQUIRED";
    VastErrorCode[VastErrorCode["COMPANION_UNABLE_TO_FETCH"] = 603] = "COMPANION_UNABLE_TO_FETCH";
    VastErrorCode[VastErrorCode["COMPANION_UNSUPPORTED_TYPE"] = 604] = "COMPANION_UNSUPPORTED_TYPE";
    // Miscellaneous errors
    VastErrorCode[VastErrorCode["UNDEFINED_ERROR"] = 900] = "UNDEFINED_ERROR";
})(VastErrorCode = exports.VastErrorCode || (exports.VastErrorCode = {}));
var ErrorType;
(function (ErrorType) {
    ErrorType["AD_LOAD"] = "AD_LOAD";
    ErrorType["AD_PLAY"] = "PLAYBACK_ERROR";
})(ErrorType = exports.ErrorType || (exports.ErrorType = {}));
class AmazonVideoAdsError {
    constructor(errorType, errorCode, message, analyticsData) {
        this.errorType = errorType;
        this.errorCode = errorCode;
        this.message = message;
        this.analyticsData = analyticsData;
    }
    withHttpError(httpErrorCode) {
        this.httpErrorCode = httpErrorCode;
        return this;
    }
    getVastErrorCode() {
        switch (this.errorCode) {
            case AmazonErrorCode.XML_PARSING_ERROR:
                return VastErrorCode.PARSING_GENERAL_ERROR;
            case AmazonErrorCode.SCHEMA_VALIDATION_ERROR:
                return VastErrorCode.PARSING_VALIDATION_ERROR;
            case AmazonErrorCode.UNSUPPORTED_VAST_VERSION_ERROR:
                return VastErrorCode.PARSING_UNSUPPORTED_VERSION_ERROR;
            case AmazonErrorCode.UNEXPECTED_AD_LINEARITY:
                return VastErrorCode.VIDEO_PLAYER_LINEARITY_ERROR;
            case AmazonErrorCode.VIDEO_PLAYER_EXPECTING_DIFFERENT_DURATION:
                return VastErrorCode.VIDEO_PLAYER_DURATION_ERROR;
            case AmazonErrorCode.XML_WRAPPER_PARSING_ERROR:
                return VastErrorCode.UNWRAP_GENERAL_ERROR;
            case AmazonErrorCode.WRAPPER_URI_TIMEOUT:
                return VastErrorCode.UNWRAP_URI_UNAVAILABLE_ERROR;
            case AmazonErrorCode.WRAPPER_DUPLICATE_URI:
                return VastErrorCode.UNWRAP_GENERAL_ERROR;
            case AmazonErrorCode.WRAPPER_LIMIT_REACHED:
                return VastErrorCode.UNWRAP_LIMIT_REACHED_ERROR;
            case AmazonErrorCode.WRAPPER_MISSING_RESPONSE:
                return VastErrorCode.UNWRAP_NO_ADS_ERROR;
            case AmazonErrorCode.MEDIA_PLAYBACK_ERROR:
                return VastErrorCode.LINEAR_GENERAL_ERROR;
            case AmazonErrorCode.MEDIA_FILE_NOT_FOUND:
                return VastErrorCode.LINEAR_NOT_FOUND_ERROR;
            case AmazonErrorCode.MEDIA_FILE_LOAD_TIMEOUT:
                return VastErrorCode.LINEAR_TIMEOUT_ERROR;
            case AmazonErrorCode.MEDIA_FILE_BUFFERING_TIMEOUT:
                return VastErrorCode.LINEAR_TIMEOUT_ERROR;
            case AmazonErrorCode.MEDIA_FILE_NOT_SUPPORTED:
                return VastErrorCode.LINEAR_NOT_SUPPORTED_ERROR;
            case AmazonErrorCode.MEDIA_FILE_UNPLAYABLE:
                return VastErrorCode.LINEAR_DISPLAY_ERROR;
            case AmazonErrorCode.AD_BREAK_TIMEOUT:
                return VastErrorCode.UNDEFINED_ERROR;
            case AmazonErrorCode.COMPANION_VAST_CONSTRAINT_REQUIRED:
                return VastErrorCode.COMPANION_UNABLE_TO_DISPLAY_REQUIRED;
            case AmazonErrorCode.COMPANION_VAST_UNSUPPORTED_TYPE:
                return VastErrorCode.COMPANION_UNSUPPORTED_TYPE;
            case AmazonErrorCode.COMPANION_VAST_UNSUPPORTED_FRAMEWORK:
                return VastErrorCode.COMPANION_UNSUPPORTED_TYPE;
            case AmazonErrorCode.COMPANION_NO_AVAILABLE_SPACE:
                return VastErrorCode.COMPANION_NO_AVAILABLE_SPACE;
            default:
                return VastErrorCode.UNDEFINED_ERROR;
        }
    }
}
exports.AmazonVideoAdsError = AmazonVideoAdsError;
