"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TrackingEvent;
(function (TrackingEvent) {
    TrackingEvent["CREATIVE_VIEW"] = "creativeView";
    TrackingEvent["START"] = "start";
    TrackingEvent["FIRST_QUARTILE"] = "firstQuartile";
    TrackingEvent["MIDPOINT"] = "midpoint";
    TrackingEvent["THIRD_QUARTILE"] = "thirdQuartile";
    TrackingEvent["COMPLETE"] = "complete";
    TrackingEvent["RESUME"] = "resume";
    TrackingEvent["PAUSE"] = "pause";
    TrackingEvent["REWIND"] = "rewind";
    TrackingEvent["SKIP"] = "skip";
    TrackingEvent["CLOSE"] = "close";
    TrackingEvent["UNMUTE"] = "unmute";
    TrackingEvent["MUTE"] = "mute";
    // TODO: The vast-parser formats progress events as 'progress-<time>'
    // We should change that parsing to be 'progress<time>' for consistency
    TrackingEvent["PROGRESS"] = "progress-";
    TrackingEvent["EXPAND"] = "expand";
    TrackingEvent["COLLAPSE"] = "collapse";
    TrackingEvent["CLICK_THROUGH"] = "clickThrough";
    TrackingEvent["IMPRESSION"] = "impression";
    TrackingEvent["ERROR"] = "error";
    TrackingEvent["VERIFICATION_NOT_EXECUTED"] = "verificationNotExecuted";
})(TrackingEvent = exports.TrackingEvent || (exports.TrackingEvent = {}));
