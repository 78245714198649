"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OMSDK_VERSION_DIR = '1_4_12';
exports.OMSDK_PARTNER_NAME = 'Amazon1';
exports.OMSDK_PARTNER_VERSION = '1.0.0';
/**
 * Get the path to the omsdk web script.
 *
 * @returns path to omsdk script
 */
exports.getOMSDKScriptPath = () => {
    return `omsdk/${exports.OMSDK_VERSION_DIR}/omweb-v1.js`;
};
