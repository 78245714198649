"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class CompanionAd {
    constructor() {
        this.id = null;
        this.width = 0;
        this.height = 0;
        this.assetWidth = 0;
        this.assetHeight = 0;
        this.adSlotID = null;
        this.required = null;
        this.type = null;
        this.apiFramework = null;
        this.staticResource = null;
        this.htmlResource = null;
        this.iframeResource = null;
        this.altText = null;
        this.companionClickThroughURLTemplate = null;
        this.companionClickTrackingURLTemplates = [];
        this.trackingEvents = {};
    }
}
exports.CompanionAd = CompanionAd;
