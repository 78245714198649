"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Icon {
    constructor() {
        this.program = null;
        this.height = 0;
        this.width = 0;
        this.xPosition = 0;
        this.yPosition = 0;
        this.apiFramework = null;
        this.offset = null;
        this.duration = 0;
        this.type = null;
        this.staticResource = null;
        this.htmlResource = null;
        this.iframeResource = null;
        this.iconClickThroughURLTemplate = null;
        this.iconClickTrackingURLTemplates = [];
        this.iconViewTrackingURLTemplate = null;
    }
}
exports.Icon = Icon;
