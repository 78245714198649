"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BASE_URL_LOCAL = 'http://0.0.0.0:8081';
exports.BASE_URL_PROD = 'https://video-player.aps.amazon-adsystem.com';
exports.BASE_URL_BETA = 'https://beta-video-player.aps.amazon-adsystem.com';
exports.ANALYTICS_SERVICE_URL_BETA = 'https://beta.video-events.publishers.advertising.a2z.com/logevent/putlog';
exports.ANALYTICS_SERVICE_URL_PROD = 'https://prod.video-events.publishers.advertising.a2z.com/logevent/putlog';
exports.ANALYTICS_SERVICE_API_VALUE = 'e9026ffd475a1a3691e6b2ce637a9b92aab1073ebf53a67c5f2583be8a804ecb';
var ReleaseEnvEnum;
(function (ReleaseEnvEnum) {
    ReleaseEnvEnum["LOCAL"] = "local";
    ReleaseEnvEnum["BETA"] = "beta";
    ReleaseEnvEnum["PROD"] = "prod";
})(ReleaseEnvEnum = exports.ReleaseEnvEnum || (exports.ReleaseEnvEnum = {}));
/**
 * Get the current release environment context
 *
 * @default
 * prod
 *
 * @returns current release environment context
 */
exports.getReleaseEnv = () => {
    if (RELEASE_ENV === ReleaseEnvEnum.LOCAL ||
        RELEASE_ENV === ReleaseEnvEnum.BETA) {
        return RELEASE_ENV;
    }
    return ReleaseEnvEnum.PROD;
};
/**
 * Get the base url based on the defined release environment.
 *
 * @returns sdk base url based on release environment
 */
exports.getReleaseEnvBaseUrl = () => {
    switch (RELEASE_ENV) {
        case ReleaseEnvEnum.LOCAL:
            return exports.BASE_URL_LOCAL;
        case ReleaseEnvEnum.BETA:
            return exports.BASE_URL_BETA;
        default:
            return exports.BASE_URL_PROD;
    }
};
/**
 * Get the path to static resources based on release environment.
 *
 * @returns base path to static resources
 */
exports.getReleaseEnvResourcesBasePath = () => {
    return 'static';
};
/**
 * Get the analytic service url based on the defined release environment.
 *
 * @returns analytic service url based on release environment
 */
exports.getReleaseEnvAnalyticServiceUrl = () => {
    switch (RELEASE_ENV) {
        case ReleaseEnvEnum.LOCAL:
        case ReleaseEnvEnum.BETA:
            return exports.ANALYTICS_SERVICE_URL_BETA;
        default:
            return exports.ANALYTICS_SERVICE_URL_PROD;
    }
};
/**
 * Get the full src path to static resources based on icon path provided.
 *
 * @param {string} iconPath the icon folder path and filename provided from PlayerControlComponent
 * @returns full src url to use in the img element
 */
exports.obtainIconSrc = (iconPath) => `${exports.getReleaseEnvBaseUrl()}/${exports.getReleaseEnvResourcesBasePath()}/${iconPath}`;
