"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Analytics_1 = require("../controllers/Analytics");
var PlayerActionTypes;
(function (PlayerActionTypes) {
    PlayerActionTypes["MIRROR_PUBLISHER_PLAYER_STATE"] = "MIRROR_PUBLISHER_PLAYER_STATE";
    PlayerActionTypes["RESET_PLAYER_LIFECYCLE"] = "RESET_PLAYER_LIFECYCLE";
    PlayerActionTypes["SET_BUFFERING"] = "SET_BUFFERING";
    PlayerActionTypes["SET_MUTED"] = "SET_MUTED";
    PlayerActionTypes["SET_VOLUME"] = "SET_VOLUME";
    PlayerActionTypes["SET_PAUSED"] = "SET_PAUSED";
    PlayerActionTypes["EXTERNAL_PLAY_TRIGGERED"] = "EXTERNAL_PLAY_TRIGGERED";
    PlayerActionTypes["SET_PLAY_STARTED"] = "SET_PLAY_STARTED";
    PlayerActionTypes["SET_PLAY_REQUESTED"] = "SET_PLAY_REQUESTED";
    PlayerActionTypes["SET_PROGRESS"] = "SET_PROGRESS";
    PlayerActionTypes["SET_LOADED_META_DATA"] = "SET_LOADED_META_DATA";
    PlayerActionTypes["SET_LAST_FRAME"] = "SET_LAST_FRAME";
    PlayerActionTypes["SET_ON_PLAYER_READY_LISTENER"] = "SET_ON_PLAYER_READY_LISTENER";
    PlayerActionTypes["SET_PLAYER_READY"] = "SET_PLAYER_READY";
    PlayerActionTypes["SET_END_CARDS_SHOWN"] = "SET_END_CARDS_SHOWN";
})(PlayerActionTypes = exports.PlayerActionTypes || (exports.PlayerActionTypes = {}));
// Resets the lifecycle properties of player state to their initial values so the
// Next ad can have a lifecycle independent of the previous ad.
exports.resetPlayerLifecycle = () => {
    return {
        type: PlayerActionTypes.RESET_PLAYER_LIFECYCLE,
    };
};
exports.setBuffering = (buffering) => {
    return {
        payload: { buffering },
        type: PlayerActionTypes.SET_BUFFERING,
    };
};
exports.setMuted = (muted) => {
    return {
        payload: { muted },
        type: PlayerActionTypes.SET_MUTED,
    };
};
exports.setVolume = (volume) => {
    return {
        payload: { volume },
        type: PlayerActionTypes.SET_VOLUME,
    };
};
exports.setPaused = (paused) => {
    return {
        payload: { paused },
        type: PlayerActionTypes.SET_PAUSED,
    };
};
exports.setExternalPlayTriggered = (externalPlayTriggered) => {
    return {
        payload: { externalPlayTriggered },
        type: PlayerActionTypes.EXTERNAL_PLAY_TRIGGERED,
    };
};
exports.setPlayStarted = (playStarted) => {
    return {
        payload: { playStarted },
        type: PlayerActionTypes.SET_PLAY_STARTED,
    };
};
exports.setProgress = (progress) => {
    return {
        payload: { progress },
        type: PlayerActionTypes.SET_PROGRESS,
    };
};
exports.setPlayRequested = (playRequested) => {
    return {
        payload: { playRequested },
        type: PlayerActionTypes.SET_PLAY_REQUESTED,
    };
};
exports.mirrorPublisherPlayerState = (publisherPlayerState) => {
    return {
        payload: publisherPlayerState,
        type: PlayerActionTypes.MIRROR_PUBLISHER_PLAYER_STATE,
    };
};
exports.setLoadedMetadata = (loadedMetadata) => {
    return {
        payload: { loadedMetadata },
        type: PlayerActionTypes.SET_LOADED_META_DATA,
    };
};
exports.setLastFrame = (isLastFrame) => {
    return {
        payload: { isLastFrame },
        type: PlayerActionTypes.SET_LAST_FRAME,
    };
};
exports.setOnPlayerReadyListener = (fn) => {
    return {
        payload: { fn },
        type: PlayerActionTypes.SET_ON_PLAYER_READY_LISTENER,
    };
};
exports.setPlayerReady = (playerReady) => {
    Analytics_1.analytics.recordEvent({
        [Analytics_1.AnalyticsEventAttribute.EVENT_TYPE]: Analytics_1.AnalyticsEventType.PLAYER_READY,
    });
    return {
        payload: { playerReady },
        type: PlayerActionTypes.SET_PLAYER_READY,
    };
};
exports.setEndCardsShown = (endCardsShown) => ({
    payload: { endCardsShown },
    type: PlayerActionTypes.SET_END_CARDS_SHOWN,
});
